import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '112px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '64px',
      marginBottom: '100px',
    },
  },
}))

export default function TabPanel({ children, currentIndex, index }) {
  const classes = useStyles()

  return (
    <div
      className={classes.root}
      role="tabpanel"
      hidden={currentIndex !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {currentIndex === index && children}
    </div>
  )
}
