import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Switch, FormControlLabel } from '@material-ui/core'
import red from '@material-ui/core/colors/red'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    marginLeft: theme.spacing(1),
  },
  smallerMarginTop: {
    marginTop: theme.spacing(-1),
  },
  thumb: {
    color: '#3ccc47',
  },
  track: {
    backgroundColor: '#3ccc47',
  },
  thumbFull: {
    color: red[400],
  },
  trackFull: {
    backgroundColor: red[400],
  },
}))

export default function ResourceFullSwitch({ isFull, onFullSwitchChange, isInFavoritesSection }) {
  const classes = useStyles()

  return (
    <FormControlLabel
      control={
        <Switch
          checked={isFull}
          onChange={(e) => onFullSwitchChange(e.target.checked)}
          name="full"
          color="default"
          classes={{
            switchBase: classes.switchBase,
            thumb: isFull ? classes.thumbFull : classes.thumb,
            track: isFull ? classes.trackFull : classes.track,
            checked: classes.checked,
          }}
          data-test="resource-full-switch"
        />
      }
      label={isFull ? "Full" : "Not Full"}
      className={clsx(classes.root, isInFavoritesSection && classes.smallerMarginTop)}
    />
  )
}
