import { useEffect, useState } from 'react'
import { DatePicker } from '@material-ui/pickers'
import { Dialog, AppBar, Toolbar, Typography, IconButton, TextField, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useGoalDialogStyles } from './goalDialogStyles'
import GoalTypeSelect from './GoalTypeSelect'
import GoalType from '../../enums/GoalType'

const emptyParams = {
  name: '',
  type: GoalType.Housing,
  dateSet: new Date(),
  dateDue: null,
  steps: '',
  support: '',
  isComplete: null,
}

export default function AddGoalDialog({ onClose, isOpen, onSubmit }) {
  const classes = useGoalDialogStyles()
  const [goalParams, setGoalParams] = useState(emptyParams)

  useEffect(() => {
    if (isOpen) {
      setGoalParams(emptyParams)
    }
  }, [isOpen])

  return (
    <Dialog className={classes.root} fullScreen onClose={onClose} open={isOpen}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Add a new goal
          </Typography>
          <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className={classes.form}>
        <TextField
          id="name"
          label="What do I want to do?"
          variant="outlined"
          fullWidth
          value={goalParams.name}
          onChange={(e) => setGoalParams({ ...goalParams, name: e.target.value })}
          className={classes.field}
          required
          inputProps={{ maxLength: 52 }}
          color="secondary"
        />
        <GoalTypeSelect goalType={goalParams.type} setGoalType={(val) => setGoalParams({ ...goalParams, type: val })} />
        <DatePicker
          id="dateSet"
          label="When did I set this goal?"
          inputVariant="outlined"
          value={goalParams.dateSet}
          onChange={(date) => setGoalParams({ ...goalParams, dateSet: date })}
          fullWidth
          className={classes.field}
          required
          autoOk
          color="secondary"
          format="MMM DD"
        />
        <DatePicker
          id="dateDue"
          clearable
          label="When do I want to do this?"
          inputVariant="outlined"
          value={goalParams.dateDue}
          onChange={(date) => setGoalParams({ ...goalParams, dateDue: date })}
          className={classes.field}
          fullWidth
          autoOk
          color="secondary"
          format="MMM DD"
        />
        <TextField
          id="steps"
          label="What steps do I need to take?"
          fullWidth
          multiline
          variant="outlined"
          color="secondary"
          rows={4}
          maxRows={300}
          value={goalParams.steps}
          onChange={(e) => setGoalParams({ ...goalParams, steps: e.target.value })}
          className={classes.field}
        />
        <TextField
          id="support"
          label="Who can support me to take these steps?"
          fullWidth
          multiline
          variant="outlined"
          color="secondary"
          rows={4}
          maxRows={300}
          value={goalParams.support}
          onChange={(e) => setGoalParams({ ...goalParams, support: e.target.value })}
          className={classes.field}
        />
        <Button
          fullWidth
          variant="contained"
          size="large"
          color="secondary"
          className={classes.submitButton}
          onClick={() => onSubmit(goalParams)}
          disabled={!goalParams.name || !goalParams.type || !goalParams.dateSet}
        >
          Add goal
        </Button>
      </div>
    </Dialog>
  )
}
