import { Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
  },
  text: {
    marginTop: theme.spacing(15),
  },
  button: {
    marginTop: theme.spacing(2),
  },
}))

export default function PasswordResetSuccessful() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography component="h1" variant="h5" className={classes.text}>
        Password reset successful!
      </Typography>
      <Button variant="contained" color="secondary" href="/" className={classes.button}>
        Back to home
      </Button>
    </div>
  )
}
