export const contactTimes = ['In the morning', 'In the afternoon', 'Any time', 'Prefer not to say']

export const contactMethods = [
  'Calling me on my phone',
  'Texting me on my phone',
  'By email',
  'Through my friend/family member',
  'Where I’m currently living',
  'Prefer not to say',
]

export const genders = ['Woman', 'Man', 'Two spirit', 'Non-binary', 'Prefer not to say']

export const locations = ['Vancouver (Other)', 'Vancouver (South)', 'Northshore', 'Richmond', 'Burnaby', 'New Westminster', 'Surrey', 'Coquitlam', 'Prefer not to say']