import clsx from 'clsx'
import { ListItem, ListItemText, ListItemSecondaryAction } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import GoalType, { GoalTypeIcon } from '../../enums/GoalType'
import { getShortFormattedDate } from '../../utils/helpers'
import { PartyPopper, BullseyeArrow } from 'mdi-material-ui'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '64px',
    backgroundColor: theme.palette.secondary.light,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    '&.MuiListItem-secondaryAction': {
      paddingRight: '68px',
    },
    '&:hover': {
      backgroundColor: '#dce5ff',
    },
  },
  celebration: {
    backgroundColor: theme.palette.accent.light,
    '&:hover': {
      backgroundColor: '#ffd9e9',
    },
  },
  icon: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(1),
    },
  },
  celebrationIcon: {
    color: theme.palette.accent.main,
  },
  name: {
    fontSize: '1.2rem',
  },
  dateDue: {
    color: theme.palette.text.secondary,
    fontSize: '1.2rem',
    [theme.breakpoints.down('xs')]: {
      right: theme.spacing(1),
    },
  },
}))

export default function Goal({ name, dateDue, type, onClick, isCelebration }) {
  const classes = useStyles()

  function getIcon() {
    let Icon = BullseyeArrow // 'other' type
    if (type in GoalType) {
      Icon = GoalTypeIcon[type]
    } else if (!type) {
      Icon = PartyPopper
    }
    return (
      <Icon
        color="secondary"
        className={clsx(classes.icon, isCelebration && classes.celebrationIcon)}
        aria-label={type ? GoalType[type] : 'Celebration'}
      />
    )
  }

  return (
    <ListItem
      className={clsx(classes.root, isCelebration && classes.celebration)}
      button
      divider
      onClick={onClick}
      data-test="goal"
    >
      {getIcon()}
      <ListItemText primary={name} primaryTypographyProps={{ className: classes.name }} />
      {dateDue && (
        <ListItemSecondaryAction className={classes.dateDue}>{getShortFormattedDate(dateDue)}</ListItemSecondaryAction>
      )}
    </ListItem>
  )
}
