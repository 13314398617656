import MainContentHeading from './common/MainContentHeading'
import MainContent from './common/MainContent'
import Resources from './common/Resources'

export default function NonClientResources() {
  return (
    <MainContent>
      <MainContentHeading text="Client resources" />
      <Resources />
    </MainContent>
  )
}
