import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, TextField, FormControl, Grid, Typography, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Autocomplete, Alert } from '@material-ui/lab'
import FormContainer from '../components/common/FormContainer'
import UserType from '../enums/UserType'
import { useApi } from '../context/ApiContext'
import { useUser } from '../context/UserContext'
import { useAuth } from '../context/AuthContext'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  formControl: {
    width: '100%',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export default function ProfileSetup() {
  const classes = useStyles()
  const api = useApi()
  const user = useUser()
  const auth = useAuth()
  const history = useHistory()

  const [profile, setProfile] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    type: null,
    participantId: null,
    connector: null,
  })
  const [connectors, setConnectors] = useState([])
  const [participantIdConfirm, setParticipantIdConfirm] = useState(null)

  useEffect(() => {
    async function getConnectors() {
      const res = await api.fetchConnectors()
      setConnectors(res)
    }

    if (user.data) {
      history.push('/')
    } else {
      getConnectors()
    }
  }, [user, history, api])

  function canSubmit() {
    const hasClientFieldsIfNeeded =
      profile.type === UserType.Client
        ? profile.connector && profile.participantId && profile.participantId === participantIdConfirm
        : true
    return profile.firstName && profile.lastName && profile.phone && hasClientFieldsIfNeeded
  }

  async function handleSubmit(e) {
    e.preventDefault()
    const userData = await api.createUser({ ...profile, type: auth.state.userType }, new Date())
    if (userData) {
      window.location.href = '/'
    }
  }

  return (
    <>
      {auth.state.isVerified && (
        <Alert onClose={null} severity="success">
          Verification successful!
        </Alert>
      )}
      <FormContainer>
        <Typography component="h1" variant="h5">
          Set up your profile
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {auth.state.userType === UserType.Client && (
              <>
                <Grid item xs={12}>
                  <TextField
                    color="secondary"
                    variant="outlined"
                    required
                    fullWidth
                    id="participantId"
                    label="Participant ID"
                    value={profile.participantId}
                    onChange={(e) => setProfile({ ...profile, participantId: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    color="secondary"
                    variant="outlined"
                    required
                    fullWidth
                    id="confirmParticipantId"
                    label="Confirm Participant ID"
                    value={participantIdConfirm}
                    onChange={(e) => setParticipantIdConfirm(e.target.value)}
                    error={participantIdConfirm !== profile.participantId}
                    helperText={participantIdConfirm !== profile.participantId ? 'PIDs must match' : null}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl required variant="outlined" className={classes.formControl}>
                    <Autocomplete
                      id="connectors"
                      value={profile.connector}
                      options={connectors}
                      getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                      onChange={(e, newValue) => setProfile({ ...profile, connector: newValue })}
                      renderInput={(params) => (
                        <TextField {...params} color="secondary" label="Select your connector *" variant="outlined" />
                      )}
                    />
                  </FormControl>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <TextField
                color="secondary"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                value={profile.firstName}
                onChange={(e) => setProfile({ ...profile, firstName: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                color="secondary"
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                value={profile.lastName}
                onChange={(e) => setProfile({ ...profile, lastName: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                color="secondary"
                variant="outlined"
                required
                fullWidth
                id="phone"
                label="Phone number"
                value={profile.phone}
                onChange={(e) => setProfile({ ...profile, phone: e.target.value })}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            size="large"
            disabled={!canSubmit()}
            className={classes.submit}
          >
            Submit
          </Button>
        </form>
        <Link href="/auth/logout" variant="body2" color="secondary">
          {'Want to set up your profile later? Log out'}
        </Link>
      </FormContainer>
    </>
  )
}
