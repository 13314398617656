import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(1)}px 0`,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}))

export default function SurveyUrl({ isOpen, timepointName, initialValue, onClose, onSave }) {
  const classes = useStyles()
  const [value, setValue] = useState(initialValue || '')

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return (
    <Dialog
      classes={{ paper: classes.root }}
      onClose={onClose}
      aria-labelledby="appointment-info"
      open={isOpen}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle disableTypography id="survey-url">
        <Typography variant="h2">{timepointName}</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          color="secondary"
          variant="outlined"
          fullWidth
          id="url"
          label="Survey URL"
          name="url"
          autoFocus
          value={value}
          onChange={(e) => setValue(e.target.value)}
          helperText={`This sets the "${timepointName}" URL for all clients.`}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={() => onSave(value)} color="secondary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
