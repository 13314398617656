import { useState } from 'react'
import clsx from 'clsx'
import { Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PhoneIcon from '@material-ui/icons/PhoneOutlined'
import WebsiteIcon from '@material-ui/icons/Language'
import AddressIcon from '@material-ui/icons/RoomOutlined'
import HoursIcon from '@material-ui/icons/Schedule'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import EditableText from './EditableText'
import ClientResourceHeader from './ClientResourceHeader'
import NonClientResourceHeader from './NonClientResourceHeader'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  full: {
    backgroundColor: '#ffd1d1',
  },
  textField: {
    width: '100%',
  },
  info: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
    },
  },
  infoRow: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
}))

export default function Resource({
  initialName,
  initialInfo,
  initialPhone,
  initialWebsite,
  initialAddress,
  initialHours,
  isFull,
  isNewResource,
  isFirst,
  isLast,
  isFavorite,
  isInFavoritesSection,
  isClient,
  onUpdate,
  onDelete,
  onMoveUp,
  onMoveDown,
  onRemoveFavorite,
  onAddFavorite,
}) {
  const classes = useStyles()
  const [isEditing, setIsEditing] = useState(isNewResource)
  const [name, setName] = useState(initialName)
  const [info, setInfo] = useState(initialInfo)
  const [phone, setPhone] = useState(initialPhone)
  const [website, setWebsite] = useState(initialWebsite)
  const [address, setAddress] = useState(initialAddress)
  const [hours, setHours] = useState(initialHours)

  function handleFinishEditing() {
    setIsEditing(false)
    onUpdate({ name, info, phone, website, address, hours })
  }

  function handleCancelEditing() {
    setIsEditing(false)
    setName(initialName)
    setInfo(initialInfo)
    setPhone(initialPhone)
    setWebsite(initialWebsite)
    setAddress(initialAddress)
    setHours(initialHours)
  }

  function getDefaultRow(Icon, text, placeholder, onChangeValue) {
    return (
      <div className={classes.infoRow} data-test={placeholder}>
        <Icon className={classes.icon} />
        <EditableText
          value={text}
          onChangeValue={onChangeValue}
          isEditing={isEditing}
          textFieldProps={{ variant: 'outlined', className: classes.textField, placeholder }}
        />
      </div>
    )
  }

  function getWebsiteRow(Icon, text) {
    return (
      <div className={classes.infoRow} data-test="Website">
        <Icon className={classes.icon} />
        <EditableText
          value={text}
          onChangeValue={(val) => setWebsite(val)}
          linkValue={text}
          isEditing={isEditing}
          textFieldProps={{ variant: 'outlined', className: classes.textField, placeholder: 'Website' }}
        />
      </div>
    )
  }

  function getPhoneRow(Icon, text) {
    return (
      <div className={classes.infoRow} data-test="Phone">
        <Icon className={classes.icon} />
        <EditableText
          value={text}
          onChangeValue={(val) => setPhone(val)}
          linkValue={`tel:${text}`}
          isEditing={isEditing}
          textFieldProps={{ variant: 'outlined', className: classes.textField, placeholder: 'Phone' }}
        />
      </div>
    )
  }

  return (
    <Paper
      className={clsx(classes.root, isFull && classes.full)}
      variant="outlined"
      data-test={isNewResource ? 'new-resource' : 'resource'}
    >
      {isClient ? (
        <ClientResourceHeader
          name={name}
          isFavorite={isFavorite}
          isInFavoritesSection={isInFavoritesSection}
          onRemoveFavorite={onRemoveFavorite}
          onAddFavorite={onAddFavorite}
          isFull={isFull}
          onFullSwitchChange={(val) => onUpdate({ isFull: val })}
          isFirst={isFirst}
          isLast={isLast}
          onMoveUp={onMoveUp}
          onMoveDown={onMoveDown}
        />
      ) : (
        <NonClientResourceHeader
          name={name}
          setName={setName}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          isFull={isFull}
          onFullSwitchChange={(val) => onUpdate({ isFull: val })}
          isNewResource={isNewResource}
          isFirst={isFirst}
          isLast={isLast}
          onMoveUp={onMoveUp}
          onMoveDown={onMoveDown}
          onDelete={onDelete}
          handleFinishEditing={handleFinishEditing}
          handleCancelEditing={handleCancelEditing}
        />
      )}
      <div className={classes.info}>
        {(isEditing || info) && getDefaultRow(InfoIcon, info, 'Info', (val) => setInfo(val))}
        {(isEditing || phone) && getPhoneRow(PhoneIcon, phone, (val) => setPhone(val))}
        {(isEditing || website) && getWebsiteRow(WebsiteIcon, website)}
        {(isEditing || address) && getDefaultRow(AddressIcon, address, 'Address', (val) => setAddress(val))}
        {(isEditing || hours) && getDefaultRow(HoursIcon, hours, 'Hours', (val) => setHours(val))}
      </div>
    </Paper>
  )
}
