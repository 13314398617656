import { createContext, useContext, useState, useEffect, useCallback } from 'react'
import { useApi } from './ApiContext'
import { useUser } from './UserContext'
import UserType from '../enums/UserType'

const NonClientContext = createContext()

function NonClientProvider({ children }) {
  const api = useApi()
  const user = useUser()
  const [connectors, setConnectors] = useState(null) // only set for researchers
  const [clients, setClients] = useState(null)
  const [currentClient, setCurrentClient] = useState(null)
  // settting isFetchingClients and isFetchingConnectors prevents extra api calls to fetch clients/connectors
  const [isFetchingClients, setIsFetchingClients] = useState(false)
  const [isFetchingConnectors, setIsFetchingConnectors] = useState(false)

  const fetchClients = useCallback(async () => {
    setIsFetchingClients(true)
    const res =
      user.data.type === UserType.Connector
        ? await api.fetchConnectorsClients(user.data.id)
        : await api.fetchAllClients(user.data.id)
    if (res) {
      setClients(res)
    }
    setIsFetchingClients(false)
  }, [api, user])

  useEffect(() => {
    if (user.data && user.data.type !== UserType.Client && !clients && !isFetchingClients) {
      fetchClients()
    }
  }, [api, clients, user, fetchClients, isFetchingClients])

  useEffect(() => {
    async function fetchConnectors() {
      setIsFetchingConnectors(true)
      const connectorsRes = await api.fetchConnectors(user.data.id)
      setConnectors(connectorsRes)
      setIsFetchingConnectors(false)
    }

    if (user.data && user.data.type === UserType.Researcher && !connectors && !isFetchingConnectors) {
      fetchConnectors()
    }
  }, [api, connectors, user, isFetchingConnectors])

  function updateClient(updatedClient) {
    setClients((prevClients) => {
      const newClients = [...prevClients]
      const index = newClients.findIndex((c) => c.id === updatedClient.id)
      newClients[index] = { ...newClients[index], ...updatedClient }
      return newClients
    })
    if (updatedClient.id === currentClient.id) {
      setCurrentClient(updatedClient)
    }
  }

  return (
    <NonClientContext.Provider
      value={{ connectors, clients, currentClient, setCurrentClient, refreshClients: fetchClients, updateClient }}
    >
      {children}
    </NonClientContext.Provider>
  )
}

function useNonClient() {
  const context = useContext(NonClientContext)
  if (context === undefined) {
    throw new Error('useNonClient must be used within a NonClientProvider')
  }
  return context
}

export { NonClientProvider, useNonClient }
