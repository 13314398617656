import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  label: {
    marginRight: theme.spacing(1),
    fontSize: '1.2rem',
    textAlign: 'right',
    flexShrink: 0,
    width: '140px',
  },
  bar: {
    width: '100%',
  },
  barFill: {
    backgroundColor: theme.palette.secondary.main,
  },
  count: {
    color: 'white',
    textAlign: 'right',
    paddingRight: theme.spacing(1),
    fontSize: '1.2rem',
  },
}))

export default function MetricBar({ count, barValue, label, labelWidth }) {
  const classes = useStyles()

  return (
    <div className={classes.root} data-test="metric-bar">
      <Typography className={classes.label} style={{ width: `${labelWidth}px` }}>
        {label}
      </Typography>
      <div className={classes.bar}>
        <div className={classes.barFill} style={{ width: `${barValue}%` }}>
          <Typography className={classes.count}>{count}</Typography>
        </div>
      </div>
    </div>
  )
}
