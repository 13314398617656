import { useRef } from 'react'
import {
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(1)}px 0`,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  textField: {
    width: '100%',
  },
}))

export default function TextFieldDialog({ onClose, onSave, isOpen, name, initialValue, isEditable }) {
  const classes = useStyles()
  const inputRef = useRef()

  return (
    <Dialog
      classes={{ paper: classes.root }}
      onClose={onClose}
      aria-labelledby={name}
      open={isOpen}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle disableTypography id={name}>
        <Typography variant="h2">{name}</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {isEditable ? (
          <TextField
            inputRef={inputRef}
            className={classes.textField}
            inputProps={{ style: { fontSize: '1.3rem' }, 'data-test': 'dialog-textfield' }}
            defaultValue={initialValue}
            placeholder="Click to edit"
            multiline
            variant="outlined"
            color="secondary"
            rows={16}
            maxRows={300}
          />
        ) : (
          <Typography>{initialValue}</Typography>
        )}
      </DialogContent>
      {isEditable && (
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={() => onSave(inputRef.current.value)} color="secondary">
            Save
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}
