import { useEffect, useState } from 'react'
import { Dialog, AppBar, Toolbar, Typography, IconButton, TextField, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useGoalDialogStyles } from './goalDialogStyles'

const emptyParams = {
  name: '',
  type: null,
  dateSet: new Date(),
  dateDue: null,
  steps: null,
  support: null,
  isComplete: null,
}

export default function AddCelebrationDialog({ onClose, isOpen, onSubmit }) {
  const classes = useGoalDialogStyles()
  const [goalParams, setGoalParams] = useState(emptyParams)

  useEffect(() => {
    if (isOpen) {
      setGoalParams(emptyParams)
    }
  }, [isOpen])

  return (
    <Dialog className={classes.root} fullScreen onClose={onClose} open={isOpen}>
      <AppBar className={classes.appBarCelebration}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Add a new celebration
          </Typography>
          <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className={classes.form}>
        <TextField
          id="name"
          label="What did I do?"
          variant="outlined"
          fullWidth
          value={goalParams.name}
          onChange={(e) => setGoalParams({ ...goalParams, name: e.target.value })}
          className={classes.field}
          required
          inputProps={{ maxLength: 52 }}
        />
        <Button
          fullWidth
          variant="contained"
          size="large"
          className={classes.submitButtonCelebration}
          onClick={() => onSubmit(goalParams)}
          disabled={!goalParams.name}
        >
          Add celebration
        </Button>
      </div>
    </Dialog>
  )
}
