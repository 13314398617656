import { Typography, TextField } from '@material-ui/core'

export default function EditableText({
  typographyProps,
  textFieldProps,
  value,
  isEditing,
  onFinishEditing = () => {},
  linkValue,
  autofocus,
  onChangeValue,
}) {
  function handleTextFieldKeyDown(e) {
    if (e.key === 'Enter') {
      onFinishEditing(value)
    }
  }

  return isEditing ? (
    <TextField
      size="small"
      inputRef={(input) => autofocus && input && input.focus()}
      value={value}
      onChange={(e) => onChangeValue(e.target.value)}
      onBlur={(e) => onFinishEditing(value)}
      onKeyDown={(e) => handleTextFieldKeyDown(e)}
      color="secondary"
      {...textFieldProps}
    />
  ) : linkValue ? (
    <Typography {...typographyProps}>
      <a href={linkValue}>{value}</a>
    </Typography>
  ) : (
    <Typography {...typographyProps}>{value || '-'}</Typography>
  )
}
