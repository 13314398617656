import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import TimelineIcon from '@material-ui/icons/CheckCircle'
import InfoIcon from '@material-ui/icons/Info'
import NotesIcon from '@material-ui/icons/Description'
import Tabs from '../components/Tabs/Tabs'
import TabPanel from '../components/Tabs/TabPanel'
import Timeline from '../components/Timeline/Timeline'
import { writeStorage, useLocalStorage } from '@rehooks/local-storage'
import { useNonClient } from '../context/NonClientContext'
import Notes from '../components/Notes'
import ClientInfo from '../components/ClientInfo/ClientInfo'
import NoClientSelected from '../components/NoClientSelected'
import { updateServiceWorkerRegistrations } from '../utils/helpers'
import { ImageFilterHdr } from 'mdi-material-ui'
import GoalSetting from '../components/GoalSetting/GoalSetting'

export default function ClientSection() {
  const nonClient = useNonClient()
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  const [initialTabIndex] = useLocalStorage('clientSectionTabIndex')
  const [tabIndex, setTabIndex] = useState(parseInt(params.get('tabIndex')) || initialTabIndex || 0)

  function handleTabChange(i) {
    setTabIndex(i)
    writeStorage('clientSectionTabIndex', i)
    updateServiceWorkerRegistrations()
  }

  const tabData = [
    { name: 'Timeline', icon: TimelineIcon },
    { name: 'Info', icon: InfoIcon },
    { name: 'Notes', icon: NotesIcon },
    { name: 'Goal setting', icon: ImageFilterHdr },
  ]

  return (
    <>
      <Tabs tabIndex={tabIndex} onTabChange={(e, i) => handleTabChange(i)} tabData={tabData} />
      <TabPanel currentIndex={tabIndex} index={0}>
        {nonClient.currentClient ? (
          <Timeline
            clientUserId={nonClient.currentClient.userId}
            clientInfoId={nonClient.currentClient.id}
            isClient={false}
          />
        ) : (
          <NoClientSelected />
        )}
      </TabPanel>
      <TabPanel currentIndex={tabIndex} index={1}>
        {nonClient.currentClient ? <ClientInfo /> : <NoClientSelected />}
      </TabPanel>
      <TabPanel currentIndex={tabIndex} index={2}>
        {nonClient.currentClient ? <Notes /> : <NoClientSelected />}
      </TabPanel>
      <TabPanel currentIndex={tabIndex} index={3}>
        {nonClient.currentClient ? (
          <GoalSetting clientInfoId={nonClient.currentClient.id} onTabChange={handleTabChange} />
        ) : (
          <NoClientSelected />
        )}
      </TabPanel>
    </>
  )
}
