import { useState } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Offline } from 'react-detect-offline'
import useVisibilityChange from 'use-visibility-change'
import { CssBaseline, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import Auth from './pages/Auth'
import ClientHome from './pages/ClientHome'
import Metrics from './pages/Metrics'
import PageNotFound from './pages/PageNotFound'
import Logout from './pages/Logout'
import ProfileSetup from './pages/ProfileSetup'
import Profile from './pages/Profile'
import Overview from './pages/Overview'
import AppWithNav from './components/common/AppWithNav'
import { useUser } from './context/UserContext'
import UserType from './enums/UserType'
import ClientSection from './pages/ClientSection'
import NonClientResources from './components/NonClientResources'
import LogoutWarningDialog from './components/LogoutWarningDialog'
import useLogoutTimer from './utils/useLogoutTimer'
import PasswordResetSuccessful from './pages/PasswordResetSuccessful'

function AuthRoutes() {
  return (
    <Switch>
      <Route path="/auth/login">
        <Auth page="login" />
      </Route>
      <Route path="/auth/registration">
        <Auth page="registration" />
      </Route>
      <Route path="/auth/verification">
        <Auth page="verification" />
      </Route>
      <Route path="/auth/recovery">
        <Auth page="recovery" />
      </Route>
      <Route path="/auth/password-reset">
        <Auth page="settings" />
      </Route>
      <Route path="/auth/logout">
        <Logout />
      </Route>
    </Switch>
  )
}

function ClientRoutes() {
  return (
    <Route exact path="/">
      <AppWithNav>
        <ClientHome />
      </AppWithNav>
    </Route>
  )
}

function ConnectorResearcherRoutes(userType) {
  return [
    <Route key="/" exact path="/">
      {userType === UserType.Researcher ? <Redirect to="/metrics" /> : <Redirect to="/overview" />}
    </Route>,
    <Route key="/overview" exact path="/overview">
      <AppWithNav>
        <Overview />
      </AppWithNav>
    </Route>,
    <Route key="/metrics" exact path="/metrics">
      <AppWithNav>
        <Metrics />
      </AppWithNav>
    </Route>,
    <Route key="/client" exact path="/client">
      <AppWithNav>
        <ClientSection />
      </AppWithNav>
    </Route>,
    <Route key="/resources" exact path="/resources">
      <AppWithNav>
        <NonClientResources />
      </AppWithNav>
    </Route>,
  ]
}

export default function App() {
  const user = useUser()
  const { showWarning, handleLogout, handleStayLoggedIn, warningSecondsLeft } = useLogoutTimer()
  const [isPageVisible, setIsPageVisible] = useState(true)

  useVisibilityChange({
    onShow: () => setIsPageVisible(true),
    onHide: () => setIsPageVisible(false),
  })

  return (
    <>
      <CssBaseline />
      <Switch>
        {user.data && user.data.type !== UserType.Client ? ConnectorResearcherRoutes(user.data.type) : ClientRoutes()}
        <Route exact path="/profile">
          <AppWithNav>
            <Profile />
          </AppWithNav>
        </Route>
        <Route exact path="/profile-setup">
          <ProfileSetup />
        </Route>
        <Route path="/auth/*">
          <AuthRoutes />
        </Route>
        <Route path="/password-reset-successful">
          <AppWithNav>
            <PasswordResetSuccessful />
          </AppWithNav>
        </Route>
        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>

      {isPageVisible && (
        <Offline polling={{ interval: 10000 }}>
          <Snackbar open={true} handleClose={null} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="error" sx={{ width: '100%' }} variant="filled">
              You have no internet connection. Some features may not work as expected.
            </Alert>
          </Snackbar>
        </Offline>
      )}

      <LogoutWarningDialog
        isOpen={showWarning}
        onLogout={handleLogout}
        onStayLoggedIn={handleStayLoggedIn}
        secondsLeft={warningSecondsLeft}
      />
    </>
  )
}
