import { Avatar, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Icon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.accent.main,
  },
  text: {
    color: theme.palette.text.secondary,
    fontSize: '2rem',
  },
}))

function PageNotFound() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Avatar className={classes.avatar}>
        <Icon />
      </Avatar>
      <Typography className={classes.text}>Page not found.</Typography>
    </div>
  )
}

export default PageNotFound
