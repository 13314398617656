import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  label: {
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    fontSize: '1rem',
  },
  value: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
}))

export default function ClientInfoField({ label, children }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography className={classes.label}>{label}</Typography>
      <div className={classes.value}>{children}</div>
    </div>
  )
}
