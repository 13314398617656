import { useState } from 'react'
import { TextField, Typography, Button, Link, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import FormContainer from '../common/FormContainer'
import UserType from '../../enums/UserType'
import { isEmailApproved } from '../../utils/helpers'
import useAuthSnackbars from './useAuthSnackbars'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  selectFormControl: {
    width: '100%',
  },
  submit: {
    margin: theme.spacing(2, 0),
  },
  errorList: {
    margin: 0,
    paddingLeft: theme.spacing(2),
  },
}))

export default function SignUp({ formAction, csrfToken, errorMsgs, infoMsgs }) {
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [userType, setUserType] = useState(null)
  useAuthSnackbars({ errorMsgs, infoMsgs })

  const isApprovedEmail = isEmailApproved(email, userType)

  return (
    <FormContainer>
      <Typography component="h1" variant="h4">
        Sign up
      </Typography>
      <form className={classes.form} noValidate method="POST" action={formAction}>
        <FormControl required variant="outlined" className={classes.selectFormControl}>
          <InputLabel id="user-type-label">User type</InputLabel>
          <Select
            autoFocus
            color="secondary"
            labelId="user-type-label"
            id="traits.user_type"
            name="traits.user_type"
            value={userType}
            onChange={(e) => {
              setUserType(e.target.value)
            }}
            label="User type"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={UserType.Client}>Client</MenuItem>
            <MenuItem value={UserType.Connector}>Connector</MenuItem>
            <MenuItem value={UserType.Researcher}>Researcher/FSC team member</MenuItem>
          </Select>
        </FormControl>
        <TextField
          color="secondary"
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="traits.email"
          label="Email address"
          name="traits.email"
          autoComplete="email"
          autoFocus
          onChange={(e) => setEmail(e.target.value)}
          error={!isApprovedEmail}
          helperText={!isApprovedEmail ? 'This email address is not approved for this user type' : null}
          inputProps={{ autoCapitalize: 'none' }}
        />
        <TextField
          color="secondary"
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          onChange={(e) => setPassword(e.target.value)}
          inputProps={{ autoCapitalize: 'none' }}
        />
        <TextField
          color="secondary"
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="confirm-password"
          label="Confirm password"
          type="password"
          id="confirm-password"
          autoComplete="current-password"
          onChange={(e) => setPasswordConfirm(e.target.value)}
          error={password !== passwordConfirm}
          helperText={password !== passwordConfirm ? 'Passwords must match' : null}
          inputProps={{ autoCapitalize: 'none' }}
        />
        <div>
          By clicking Sign Up, you agree to our <a target="_blank" href="/terms-of-service.html">
            Terms of Service
          </a> and <a target="_blank" href="/privacy-policy.html">
            Data Policy
          </a>.
        </div>
        <input type="hidden" value={csrfToken} readOnly name="csrf_token" required />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          size="large"
          color="secondary"
          className={classes.submit}
          disabled={!userType || !email || !isApprovedEmail || !password || password !== passwordConfirm}
        >
          Sign up
        </Button>
      </form>
      <Link href="/auth/login" variant="body2" color="secondary">
        Already have an account? Log in
      </Link>
    </FormContainer>
  )
}
