import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    color: 'white',
    textDecoration: 'none',
    textTransform: 'uppercase',
    padding: `0 ${theme.spacing(2)}px`,
  },
  selected: {
    textDecoration: 'underline',
    fontWeight: 700,
  },
}))

export default function NavLink({ to, label, isSelected }) {
  const classes = useStyles()

  return (
    <Link className={clsx(classes.root, isSelected && classes.selected)} to={to}>
      {label}
    </Link>
  )
}
