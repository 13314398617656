import { useEffect, useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Timeline as MuiTimeline } from '@material-ui/lab'
import { useApi } from '../../context/ApiContext'
import Spinner from '../common/Spinner'
import Timepoint from './Timepoint'
import NoReminder from './NoReminder'
import EventDetails from './EventDetails'
import AppointmentReminder from './AppointmentReminder'
import SurveyReminder from './SurveyReminder'
import MainContentHeading from '../common/MainContentHeading'
import MainContent from '../common/MainContent'
import TimepointType from '../../enums/TimepointType'
import {
  getDaysBetweenTodayAndDate,
  getJsDateFromPostgresTimestamp,
  addDaysToDate,
  addMonthsToDate,
  isDateTomorrow,
  isDateToday,
  isDateBeforeTomorrow,
  getExtraShortFormattedDate,
} from '../../utils/helpers'
import Survey from '../common/Survey'
import TimepointNotes from './TimepointNotes'
import TimepointProtocol from './TimepointProtocol'
import TimepointTypeSelect from './TimepointTypeSelect'
import { useUser } from '../../context/UserContext'
import SurveyUrl from './SurveyUrl'
import { SURVEY_MONTH_OFFSETS } from '../../constants'

export const ModalType = {
  EventDetails: 0,
  Protocol: 1,
  Notes: 2,
  Survey: 3,
  SurveyUrl: 4,
  TimepointType: 5,
}

const useStyles = makeStyles((theme) => ({
  timeline: {
    padding: 0,
    marginTop: 0,
    alignItems: 'center',
    '@media (max-width:480px)': {
      alignItems: 'unset',
    },
  },
}))

function isTimepointAppointmentOrPhoneCall(t) {
  return t.timepointType === TimepointType.Appointment || t.timepointType === TimepointType.PhoneCall
}

export default function Timeline({ isClient, clientInfoId, onTabChange }) {
  const classes = useStyles()
  const api = useApi()
  const user = useUser()

  const [timepoints, setTimepoints] = useState(null)
  const [reminderTimepoint, setReminderTimepoint] = useState(null)
  const [modalInfo, setModalInfo] = useState({ timepointId: null, modalType: null })

  const fetchTimepoints = useCallback(async () => {
    setReminderTimepoint(null)

    const tp = await api.fetchClientTimepoints(clientInfoId, isClient)
    tp.sort((a, b) => a.sequenceIndex - b.sequenceIndex)
    tp.forEach((timepoint) => {
      timepoint.date = getJsDateFromPostgresTimestamp(timepoint.date)
    })

    // set survey target dates
    const startDateTimepoint = tp.find(
      (timepoint) => timepoint.name === 'Cash transfer' || timepoint.name === 'Start date'
    )
    if (startDateTimepoint.date) {
      const surveyTimepoints = tp.filter((timepoint) => timepoint.hasSurvey)
      surveyTimepoints.forEach((timepoint) => {
        const monthsAfterStartDate = SURVEY_MONTH_OFFSETS[timepoint.name]
        const targetDate = addMonthsToDate(monthsAfterStartDate, startDateTimepoint.date)
        timepoint.targetDate = getExtraShortFormattedDate(targetDate)
      })
    }

    setTimepoints(tp)

    // appointment/phonecall reminder for today
    const incompleteTimepointToday = tp.find(
      (t) => t.date && !t.isComplete && isTimepointAppointmentOrPhoneCall(t) && isDateToday(t.date)
    )
    // the reminder will only display until ~30 min after the appointment starts
    if (incompleteTimepointToday && getDaysBetweenTodayAndDate(incompleteTimepointToday.date) > -0.02) {
      setReminderTimepoint(incompleteTimepointToday)
      return
    }

    // appointment/phonecall reminder for tomorrow
    const incompleteTimepointTomorrow = tp.find(
      (t) => t.date && !t.isComplete && isTimepointAppointmentOrPhoneCall(t) && isDateTomorrow(t.date)
    )
    if (incompleteTimepointTomorrow) {
      setReminderTimepoint(incompleteTimepointTomorrow)
      return
    }

    // survey reminder
    if (isClient) {
      // surveys can't be done until the day their release date
      // so we only want to show a reminder if the release day is before tomorrow
      const latestIncompleteTimepointBeforeTomorrow = tp.reduce((latest, curr) => {
        if (
          curr.date &&
          !curr.isComplete &&
          (!latest || !latest.date || curr.date > latest.date) &&
          isDateBeforeTomorrow(curr.date)
        ) {
          return curr
        }
        return latest
      }, null)

      if (
        latestIncompleteTimepointBeforeTomorrow &&
        latestIncompleteTimepointBeforeTomorrow.timepointType === TimepointType.Diy
      ) {
        const daysUntilStartDate = getDaysBetweenTodayAndDate(latestIncompleteTimepointBeforeTomorrow.date)
        const daysUntilEndDate = getDaysBetweenTodayAndDate(
          addDaysToDate(14, latestIncompleteTimepointBeforeTomorrow.date)
        )
        // the reminder will appear on the day of the survey date and disappear 14 days later
        if (daysUntilStartDate < 0 && daysUntilEndDate > 0) {
          setReminderTimepoint(latestIncompleteTimepointBeforeTomorrow)
        }
      }
    }
  }, [api, clientInfoId, isClient])

  useEffect(() => {
    fetchTimepoints()
  }, [api, fetchTimepoints])

  async function handleSurveyEnd(e) {
    const surveyTimepoint = timepoints.find((t) => t.timepointId === modalInfo.timepointId)

    const res = await api.updateClientTimepoint(surveyTimepoint.clientInfoId, surveyTimepoint.timepointId, {
      isComplete: true,
    })
    if (res) {
      fetchTimepoints()
    }
  }

  function getReminder() {
    if (
      reminderTimepoint.timepointType === TimepointType.Appointment ||
      reminderTimepoint.timepointType === TimepointType.PhoneCall
    ) {
      return (
        <AppointmentReminder
          name={reminderTimepoint.name}
          date={reminderTimepoint.date}
          address={reminderTimepoint.address}
          isPhoneCall={reminderTimepoint.timepointType === TimepointType.PhoneCall}
        />
      )
    }
    if (reminderTimepoint.timepointType === TimepointType.Diy) {
      return (
        <SurveyReminder
          name={reminderTimepoint.name}
          startDate={reminderTimepoint.date}
          onOpenSurvey={() => setModalInfo({ timepointId: reminderTimepoint.timepointId, modalType: ModalType.Survey })}
        />
      )
    }
    return <NoReminder />
  }

  function getEventDetails() {
    if (modalInfo.modalType === ModalType.EventDetails) {
      const tp = timepoints.find((t) => t.timepointId === modalInfo.timepointId)
      return {
        name: tp.name,
        initialDate: tp.date,
        initialAddress: tp.address,
        timepointType: tp.timepointType,
        timepointId: tp.timepointId,
      }
    }
    return null
  }

  function handleOpenModal(timepointId, modalType) {
    setModalInfo({ timepointId, modalType })
  }

  function handleCloseModal() {
    setModalInfo({ timepointId: modalInfo.timepointId, modalType: null })
  }

  async function handleUpdateClientTimepoint(timepointId, propertiesToUpdate) {
    await api.updateClientTimepoint(clientInfoId, timepointId, propertiesToUpdate)
    handleCloseModal()
    fetchTimepoints()
  }

  async function handleUpdateTimepoint(timepointId, propertiesToUpdate) {
    await api.updateTimepoint(timepointId, propertiesToUpdate)
    handleCloseModal()
    fetchTimepoints()
  }

  function getTimepointProperty(timepointId, property) {
    return timepoints.find((t) => t.timepointId === timepointId)[property]
  }

  function handleToggleIsComplete(timepointId) {
    const isComplete = getTimepointProperty(timepointId, 'isComplete')
    handleUpdateClientTimepoint(timepointId, { isComplete: !isComplete })
  }

  if (!timepoints) {
    return <Spinner />
  }

  return (
    <>
      {reminderTimepoint && getReminder()}
      <MainContent>
        <MainContentHeading text={isClient ? 'My progress' : "Your client's progress"} />
        <MuiTimeline className={classes.timeline} align="alternate">
          {timepoints.map((timepoint, i) => (
            <Timepoint
              key={timepoint.name}
              isClient={isClient}
              data={timepoint}
              isAlternate={!(i % 2)}
              isLast={i === timepoints.length - 1}
              onOpenModal={handleOpenModal}
              userType={user.data.type}
              onToggleIsComplete={handleToggleIsComplete}
            />
          ))}
        </MuiTimeline>
        <Survey
          isOpen={modalInfo.modalType === ModalType.Survey}
          title={modalInfo.modalType === ModalType.Survey && getTimepointProperty(modalInfo.timepointId, 'name')}
          onClose={handleCloseModal}
          onSurveyEnd={handleSurveyEnd}
          url={modalInfo.modalType === ModalType.Survey && getTimepointProperty(modalInfo.timepointId, 'surveyUrl')}
        />
        <EventDetails
          isClient={isClient}
          isOpen={modalInfo.modalType === ModalType.EventDetails}
          onClose={handleCloseModal}
          onSave={({ address, date }) => handleUpdateClientTimepoint(modalInfo.timepointId, { address, date })}
          onGoToContacts={() => onTabChange(2)}
          {...getEventDetails()}
        />
        <TimepointNotes
          isOpen={modalInfo.modalType === ModalType.Notes}
          onClose={handleCloseModal}
          onSave={(notes) => handleUpdateClientTimepoint(modalInfo.timepointId, { notes })}
          timepointName={modalInfo.modalType === ModalType.Notes && getTimepointProperty(modalInfo.timepointId, 'name')}
          notes={modalInfo.modalType === ModalType.Notes && getTimepointProperty(modalInfo.timepointId, 'notes')}
          userType={user.data.type}
        />
        <TimepointProtocol
          isOpen={modalInfo.modalType === ModalType.Protocol}
          onClose={handleCloseModal}
          onSave={(protocol) => handleUpdateTimepoint(modalInfo.timepointId, { protocol })}
          timepointName={
            modalInfo.modalType === ModalType.Protocol && getTimepointProperty(modalInfo.timepointId, 'name')
          }
          protocol={
            modalInfo.modalType === ModalType.Protocol && getTimepointProperty(modalInfo.timepointId, 'protocol')
          }
          userType={user.data.type}
        />
        <TimepointTypeSelect
          isOpen={modalInfo.modalType === ModalType.TimepointType}
          onClose={handleCloseModal}
          onSave={(timepointType) => handleUpdateClientTimepoint(modalInfo.timepointId, { timepointType })}
          timepointName={
            modalInfo.modalType === ModalType.TimepointType && getTimepointProperty(modalInfo.timepointId, 'name')
          }
          initialValue={
            modalInfo.modalType === ModalType.TimepointType &&
            getTimepointProperty(modalInfo.timepointId, 'timepointType')
          }
        />
        <SurveyUrl
          isOpen={modalInfo.modalType === ModalType.SurveyUrl}
          onClose={handleCloseModal}
          onSave={(surveyUrl) => handleUpdateTimepoint(modalInfo.timepointId, { surveyUrl })}
          timepointName={
            modalInfo.modalType === ModalType.SurveyUrl && getTimepointProperty(modalInfo.timepointId, 'name')
          }
          initialValue={
            modalInfo.modalType === ModalType.SurveyUrl && getTimepointProperty(modalInfo.timepointId, 'surveyUrl')
          }
        />
      </MainContent>
    </>
  )
}
