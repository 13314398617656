import { useState, useRef } from 'react'
import { Collapse, TextField, Typography, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import MainContentHeading from './common/MainContentHeading'
import { useApi } from '../context/ApiContext'
import { useNonClient } from '../context/NonClientContext'
import { useUser } from '../context/UserContext'
import MainContent from './common/MainContent'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
  },
  collapseTriggerButton: {
    // color: theme.palette.secondary.light,
    marginLeft: theme.spacing(-0.5),
  },
  collapseTriggerText: {
    textTransform: 'none',
    textAlign: 'left',
    textDecoration: 'underline',
  },
  collapsibleText: {
    margin: theme.spacing(1),
  },
  tapToEdit: {
    marginTop: theme.spacing(2),
  },
  textField: {
    width: '100%',
  },
  list: {
    margin: theme.spacing(1, 0),
  },
}))

export default function Notes() {
  const classes = useStyles()
  const api = useApi()
  const nonClient = useNonClient()
  const user = useUser()

  const [isTextFieldFocused, setIsTextFieldFocused] = useState(false)
  const [showContactFormat, setShowContactFormat] = useState(false)

  const inputRef = useRef()

  const client = nonClient.currentClient

  async function handleBlur() {
    setIsTextFieldFocused(false)
    const updatedClientInfo = await api.updateClientInfo(client.id, user.data.type, {
      connectorNotes: inputRef.current.value,
    })
    nonClient.updateClient(updatedClientInfo)
  }

  return (
    <MainContent>
      <MainContentHeading text="Notes about your client" />
      <div className={classes.root}>
        <Typography>
          Please use this space to log the following:
          <ul className={classes.list}>
            <li>Previous contact detail notes</li>
            <li>Incident reports</li>
            <li>General notes</li>
          </ul>
        </Typography>
        <Button
          className={classes.collapseTriggerButton}
          onClick={() => setShowContactFormat(!showContactFormat)}
          size="small"
        >
          <Typography className={classes.collapseTriggerText}>
            Follow the protocols below to record notes in the correct format.
          </Typography>
          {showContactFormat ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
        <Collapse in={showContactFormat}>
          <Typography className={classes.collapsibleText}>
            <strong>Previous contact detail notes</strong> - Connectors must use this to record old contact details
            whenever a new contact detail is updated in a client's main profile.
            <br />
            [Use this title] <strong>Previous contact detail log</strong>
            <br />
            -[Date of update] <strong>04/09/2022</strong>
            <br />
            -[Type of contact detail updated] <strong>email</strong>
            <br />
            -[Old contact] <strong>bob@gmail.com</strong>
            <br />
            <br />
            <strong>Incident reports</strong> - Connectors must make a record of any incident reports here as well as
            filing an official incident report.
            <br />
            [Use this title] <strong>Incident Report</strong>
            <br />
            -[Date of incident] <strong>04/09/2022</strong>
            <br />
            -[Incident summary- provide one sentence about this incident]
            <br />
            -[Incident Report filed?] <strong>Incident report filed/Incident report not file</strong>
            <br />
            <br />
            <strong>General note log</strong> - Connectors may log anything not specific to a follow up time point under
            a 'general note log'.
            <br />
            [Use this title] <strong>General note log</strong>
            <br />
            -[Date of log] <strong>04/09/2022</strong>
            <br />
            -[Description-detail can be provided in freeform text here]
            <br />
            <br />
            <strong>Withdrawal notes-</strong> - Connectors should use this to record the reason for clients that become inactive or withdraw from the study. If they don't know, they should still indicate this.
            <br />
            [Use this title] <strong>Withdrawal notes</strong>
            <br />
            -[Date of client's withdrawal] <strong>04/09/2022</strong>
            <br />
            -[Reason for withdrawal/inactivity] <strong>Write copy here</strong>
            <br />
            <br />
          </Typography>
        </Collapse>
        <Typography color="textSecondary" className={classes.tapToEdit}>
          {isTextFieldFocused ? 'Tap away to save' : 'Tap to edit'}
        </Typography>
        <TextField
          inputRef={inputRef}
          className={classes.textField}
          inputProps={{ style: { fontSize: '1.3rem', lineHeight: '1.8rem' }, 'data-test': 'notes-textfield' }}
          defaultValue={client.connectorNotes}
          placeholder="Tap to edit..."
          multiline
          variant="outlined"
          color="secondary"
          rows={12}
          maxRows={300}
          onFocus={() => setIsTextFieldFocused(true)}
          onBlur={handleBlur}
        />
      </div>
    </MainContent>
  )
}
