import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Select, FormControl, MenuItem } from '@material-ui/core'
import ClientStatus, { getClientStatusString } from '../../enums/ClientStatus'
import { useNonClient } from '../../context/NonClientContext'
import { useUser } from '../../context/UserContext'
import { useApi } from '../../context/ApiContext'

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginLeft: theme.spacing(1),
  },
  select: {
    width: '204px',
    background: 'white',
  },
  textField: {
    background: 'white',
    borderRadius: '4px',
  },
  activeStatus: {
    background: '#d1ffd2',
  },
  delayedStatus: {
    background: '#ffd1d1',
  },
  inactiveStatus: {
    background: '#fff0c1',
  },
}))

export default function ClientStatusSelect() {
  const classes = useStyles()
  const user = useUser()
  const nonClient = useNonClient()
  const api = useApi()

  const client = nonClient.currentClient

  async function setStatus(status) {
    const updatedClientInfo = await api.updateClientInfo(client.id, user.data.type, { status })
    nonClient.updateClient(updatedClientInfo)
  }

  if (!client) {
    return null
  }

  const selectClassName = clsx(
    classes.select,
    client.status === ClientStatus.Active && classes.activeStatus,
    (client.status === ClientStatus.DelayedAtRisk || client.status === ClientStatus.DelayedInContact) &&
      classes.delayedStatus,
    (client.status === ClientStatus.Inactive ||
      client.status === ClientStatus.Withdrawn ||
      client.status === ClientStatus.PassedAway) &&
      classes.inactiveStatus
  )

  return (
    <FormControl variant="outlined" size="small" className={classes.formControl}>
      <Select
        value={client.status}
        onChange={(e) => setStatus(e.target.value)}
        size="small"
        className={selectClassName}
        data-test="client-status-select"
      >
        {Object.values(ClientStatus).map((s) => (
          <MenuItem key={s} value={s}>
            {getClientStatusString(s)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
