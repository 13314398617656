import { useState } from 'react'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/MoreVert'
import UserType from '../../enums/UserType'
import TimepointType from '../../enums/TimepointType'
import { ModalType } from './Timeline'

export default function TimepointMenu({
  timepointId,
  timepointType,
  isComplete,
  userType,
  hasSurvey,
  onOpenModal,
  onToggleIsComplete,
}) {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)

  function handleMenuItemClick(modalType) {
    setMenuAnchorEl(null)
    onOpenModal(timepointId, modalType)
  }

  function handleToggleIsComplete() {
    setMenuAnchorEl(null)
    onToggleIsComplete(timepointId)
  }

  return (
    <>
      <IconButton
        edge="end"
        size="small"
        aria-controls="timepoint-menu"
        aria-haspopup="true"
        onClick={(e) => setMenuAnchorEl(e.currentTarget)}
        data-test="timepoint-menu-button"
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}
        MenuListProps={{ 'data-test': 'timepoint-menu' }}
      >
        <MenuItem onClick={handleToggleIsComplete}>{isComplete ? 'Mark incomplete' : 'Mark complete'}</MenuItem>
        {timepointType !== TimepointType.NoAction && (
          <MenuItem onClick={() => handleMenuItemClick(ModalType.TimepointType)}>Change appointment type</MenuItem>
        )}
        {timepointType === TimepointType.Appointment && (
          <MenuItem onClick={() => handleMenuItemClick(ModalType.EventDetails)}>Edit appointment details</MenuItem>
        )}
        {timepointType === TimepointType.PhoneCall && (
          <MenuItem onClick={() => handleMenuItemClick(ModalType.EventDetails)}>Edit phone call date/time</MenuItem>
        )}
        {timepointType === TimepointType.Diy && (
          <MenuItem onClick={() => handleMenuItemClick(ModalType.EventDetails)}>Edit survey release date</MenuItem>
        )}
        {timepointType === TimepointType.NoAction && (
          <MenuItem onClick={() => handleMenuItemClick(ModalType.EventDetails)}>Edit date</MenuItem>
        )}
        <MenuItem onClick={() => handleMenuItemClick(ModalType.Protocol)}>
          {userType === UserType.Connector ? 'View protocol' : 'Edit protocol'}
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick(ModalType.Notes)}>
          Edit notes
          {/* {userType === UserType.Connector ? 'Edit notes' : 'View notes'} */}
        </MenuItem>
        {hasSurvey && <MenuItem onClick={() => handleMenuItemClick(ModalType.Survey)}>Open survey</MenuItem>}
        {hasSurvey && userType === UserType.Researcher && (
          <MenuItem onClick={() => handleMenuItemClick(ModalType.SurveyUrl)}>Edit survey URL</MenuItem>
        )}
      </Menu>
    </>
  )
}
