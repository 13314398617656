import { useState } from 'react'
import { Typography, Button, Link, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import FormContainer from '../common/FormContainer'
import { useAuth } from '../../context/AuthContext'
import useAuthSnackbars from './useAuthSnackbars'

const useStyles = makeStyles((theme) => ({
  form: {
    textAlign: 'center',
  },
  submit: {
    margin: theme.spacing(3, 0, 1),
  },
  errorList: {
    margin: 0,
    paddingLeft: theme.spacing(2),
  },
}))

export default function VerificationPending({ formAction, csrfToken, errorMsgs, infoMsgs }) {
  const classes = useStyles()
  const auth = useAuth()
  useAuthSnackbars({ errorMsgs, infoMsgs })

  return (
    <FormContainer maxWidth="lg">
      <Typography className={classes.text}>
        A verification link has been sent to your email address (make sure to check your spam folder).
      </Typography>
      <Typography className={classes.text}>
        Please click the link in your email to continue setting up your account.
      </Typography>
      <form className={classes.form} noValidate method="POST" action={formAction}>
        <input type="hidden" value={auth.state.email} readOnly name="email" required />
        <input type="hidden" value={csrfToken} readOnly name="csrf_token" required />
        <Button type="submit" variant="contained" size="large" color="secondary" className={classes.submit}>
          Resend verification email
        </Button>
      </form>
      <Link href="/auth/logout" variant="body2" color="secondary" className={classes.logout}>
        {'Or click here to log out.'}
      </Link>
    </FormContainer>
  )
}
