const ResourceCategory = {
  EmergencyHealthcare: 'Emergency Healthcare',
  CrisisHotlines: 'Crisis Hotlines',
  EmergencyShelter: 'Emergency Shelter',
  Events: 'Events',
  WalkInClinics: 'Walk-in Clinics',
  MentalHealth: 'Mental Health',
  Dental: 'Dental',
  Education: 'Education',
  CulturalResources: 'Cultural Resources',
  LeisureAndRecreation: 'Leisure & Recreation',
  Housing: 'Housing',
  Employment: 'Employment',
  HarmReduction: 'Harm Reduction',
  LifeTransitionSupport: 'Life Transition Support',
  ReplacementId: 'Replacement ID',
  PetSupport: 'Pet Support',
}

export default ResourceCategory
