import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { fetchRequestData, generateFormRequestUrl } from '../api/kratos'
import LogIn from '../components/auth/LogIn'
import SignUp from '../components/auth/SignUp'
import PasswordRecovery from '../components/auth/AccountRecovery'
import Spinner from '../components/common/Spinner'
import Verification from '../components/auth/Verification'
import PasswordReset from '../components/auth/PasswordReset'

//import './Auth.css'

/**
 * AuthPage controller, this is the form wrapper to handle the request / error message
 * and doing the data massaging for form rendering
 * this page is responsible for all the authForms [registration/login/settings/recovery]
 *
 *  The basic login flow:
 * 1. User lands on `auth/login`
 * 2. We detect there's no flowID (login session in progress), therefore redirect to kratos:self-serve
 * 3. kratos redirects to `auth/login?flow=<flowID>`
 * 4. Given flowID we use fetch the form fields/action (`fetchRequestData` via `oathkeeper`)
 * 5. set states of FormData based on response of 4., renders form (note it can have multiple forms [odic / password])
 * 6. Form submits to kratos (form action from response of 4.), and obtain session and stored in cookie
 */
function Auth({ page }) {
  const queryParams = new URLSearchParams(useLocation().search)
  const initialState = {
    data: [],
    page: page,
    hasError: false,
    isLoading: true,
    formStatus: null,
  }

  const [formData, setFormData] = useState(initialState)
  useEffect(() => {
    const requestId = queryParams.get('flow')
    if (requestId === null) {
      generateFormRequestUrl(formData.page).then((url) => {
        window.location.href = url
      })
    } else {
      fetchRequestData(page, requestId).then((resp) => {
        if (!resp) {
          setFormData({ ...formData, hasError: true })
          return
        }
        setFormData({
          ...formData,
          //data: formCustomizations(page, resp),
          data: resp,
          formStatus: resp.state || null,
          isLoading: false,
        })
      })
    }
  }, []) // eslint-disable-line

  if (formData.hasError) {
    // If formRequest returns 400 (Usually means expired/invalid requestID)
    // redirect back to login page to restart flow
    setTimeout(() => {
      window.location.href = `/auth/${page}`
    }, 1000)
    return (
      <div className="auth-container content-container">
        <p>
          Expired or invalid {page} request.
          <br />
          <br />
          ...redirecting back to {page} page.
        </p>
      </div>
    )
  }

  if (formData.isLoading) {
    return <Spinner />
  }

  const config =
    page === 'recovery' || page === 'verification'
      ? formData.data.methods.link.config
      : formData.data.methods.password.config
  let globalInfoMsgs = []
  let globalErrorMsgs = []

  if (formData.data.messages) {
    formData.data.messages.forEach((m) => {
      if (m.type === 'info') {
        globalInfoMsgs.push(m.text)
      } else {
        globalErrorMsgs.push(m.text)
      }
    })
  }

  let configInfoMsgs = []
  let configErrorMsgs = []

  if (config.messages) {
    config.messages.forEach((m) => {
      if (m.type === 'info') {
        configInfoMsgs.push(m.text)
      } else {
        configErrorMsgs.push(m.text)
      }
    })
  }

  const fields = config.fields
  let fieldInfoMsgs = []
  let fieldErrorMsgs = []

  fields.forEach((field) => {
    if (field.messages) {
      field.messages.forEach((m) => {
        if (m.type === 'info') {
          fieldInfoMsgs.push(`${field.name === 'identifier' ? 'email' : field.name}: ${m.text}`)
        } else {
          fieldErrorMsgs.push(`${field.name === 'identifier' ? 'email' : field.name}: ${m.text}`)
        }
      })
    }
  })

  const allInfoMsgs = [...globalInfoMsgs, ...configInfoMsgs, ...fieldInfoMsgs]
  const allErrorMsgs = [...globalErrorMsgs, ...configErrorMsgs, ...fieldErrorMsgs]

  const csrfToken = fields.find((f) => f.name === 'csrf_token').value

  if (page === 'registration') {
    return <SignUp formAction={config.action} csrfToken={csrfToken} errorMsgs={allErrorMsgs} infoMsgs={allInfoMsgs} />
  }

  if (page === 'login') {
    return <LogIn formAction={config.action} csrfToken={csrfToken} errorMsgs={allErrorMsgs} infoMsgs={allInfoMsgs} />
  }

  if (page === 'recovery') {
    return (
      <PasswordRecovery
        formAction={config.action}
        csrfToken={csrfToken}
        errorMsgs={allErrorMsgs}
        infoMsgs={allInfoMsgs}
      />
    )
  }

  if (page === 'verification') {
    return (
      <Verification formAction={config.action} csrfToken={csrfToken} errorMsgs={allErrorMsgs} infoMsgs={allInfoMsgs} />
    )
  }

  if (page === 'settings') {
    return (
      <PasswordReset formAction={config.action} csrfToken={csrfToken} errorMsgs={allErrorMsgs} infoMsgs={allInfoMsgs} />
    )
  }
}

export default Auth
