import { useState } from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Typography, Button, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AddIcon from '@material-ui/icons/Add'
import Resource from './Resource'
import ResourceCategory from '../../enums/ResourceCategory'
import useResources from '../../utils/useResources'
import { useUser } from '../../context/UserContext'
import UserType from '../../enums/UserType'
import DeleteResourceDialog from './DeleteResourceDialog'
import { useClient } from '../../context/ClientContext'

const useStyles = makeStyles((theme) => ({
  category: {
    background: '#ebf0ff',
  },
  resourceList: {
    display: 'block',
  },
  addButton: {
    marginLeft: 'auto',
  },
  noResources: {
    padding: theme.spacing(1),
  },
}))

const categories = Object.values(ResourceCategory)

export default function Resources() {
  const classes = useStyles()
  const user = useUser()
  const client = useClient()

  const isClient = user.data.type === UserType.Client

  const {
    clientResources,
    resourcesByCategory,
    fetchResourcesForCategory,
    createResource,
    deleteResource,
    updateResource,
    moveResourceUp,
    moveResourceDown,
    addClientResource,
    removeClientResource,
    moveClientResourceUp,
    moveClientResourceDown,
  } = useResources(client.info && client.info.id ? client.info.id : null)

  const [resourceToDelete, setResourceToDelete] = useState(null)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [categoryWithNewResource, setCategoryWithNewResource] = useState(null)

  function handleTryDelete(resource) {
    setResourceToDelete(resource)
    setIsDeleteDialogOpen(true)
  }

  function handleAccordionChange(expanded, category) {
    if (expanded && !resourcesByCategory[category]) {
      fetchResourcesForCategory(category)
    }
  }

  async function handleConfirmDelete() {
    deleteResource(resourceToDelete)
    setIsDeleteDialogOpen(false)
  }

  async function handleCreateResource(params) {
    createResource(params)
    setCategoryWithNewResource(null)
  }

  return (
    <>
      {isClient && (
        <Accordion 
          className={classes.category} 
          data-test="resource-accordion" 
          defaultExpanded="true"
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h2" className={classes.heading}>
              Starred Resources
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.resourceList}>
            {clientResources.length ? (
              clientResources.map((resource, i) => (
                <Resource
                  key={resource.name}
                  initialName={resource.name}
                  initialInfo={resource.info}
                  initialPhone={resource.phone}
                  initialWebsite={resource.website}
                  initialAddress={resource.address}
                  initialHours={resource.hours}
                  isFull={resource.isFull}
                  isFirst={i === 0}
                  isLast={i === clientResources.length - 1}
                  isFavorite={true}
                  isInFavoritesSection={true}
                  isClient={isClient}
                  onDelete={() => handleTryDelete(resource)}
                  onUpdate={(params) => updateResource(resource.id, params)}
                  onMoveDown={() => moveClientResourceDown(client.info.id, resource.id)}
                  onMoveUp={() => moveClientResourceUp(client.info.id, resource.id)}
                  onAddFavorite={() => addClientResource(client.info.id, resource.id)}
                  onRemoveFavorite={() => removeClientResource(client.info.id, resource.id)}
                />
              ))
            ) : (
              <Paper variant="outlined" className={classes.noResources}>
                <Typography>Click the star beside resource names to add them here.</Typography>
              </Paper>
            )}
          </AccordionDetails>
        </Accordion>
      )}

      {categories.map((category) => (
        <Accordion
          className={classes.category}
          key={category}
          onChange={(e, expanded) => handleAccordionChange(expanded, category)}
          data-test="resource-accordion"
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h2" className={classes.heading}>
              {category}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.resourceList}>
            {resourcesByCategory[category] && resourcesByCategory[category].length ? (
              resourcesByCategory[category].map((resource, i) => (
                <Resource
                  key={resource.name}
                  initialName={resource.name}
                  initialInfo={resource.info}
                  initialPhone={resource.phone}
                  initialWebsite={resource.website}
                  initialAddress={resource.address}
                  initialHours={resource.hours}
                  isFull={resource.isFull}
                  isFirst={i === 0}
                  isLast={i === resourcesByCategory[category].length - 1}
                  isFavorite={!!clientResources.find((r) => r.id === resource.id)}
                  isInFavoritesSection={false}
                  isClient={isClient}
                  onDelete={() => handleTryDelete(resource)}
                  onUpdate={(params) => updateResource(resource.id, params)}
                  onMoveDown={() => moveResourceDown(resource.id, category)}
                  onMoveUp={() => moveResourceUp(resource.id, category)}
                  onAddFavorite={isClient ? () => addClientResource(client.info.id, resource.id) : null}
                  onRemoveFavorite={isClient ? () => removeClientResource(client.info.id, resource.id) : null}
                />
              ))
            ) : (
              <Paper variant="outlined" className={classes.noResources}>
                <Typography>There are no resources in this category yet.</Typography>
              </Paper>
            )}
            {category === categoryWithNewResource && (
              <Resource
                key="new-resource"
                isNewResource
                isClient={false}
                onDelete={() => setCategoryWithNewResource(null)}
                onUpdate={(params) => handleCreateResource({ ...params, category })}
              />
            )}
            {!isClient && (
              <Button
                color="secondary"
                variant="outlined"
                startIcon={<AddIcon />}
                disabled={categoryWithNewResource !== null}
                onClick={() => setCategoryWithNewResource(category)}
              >
                Add resource
              </Button>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
      {!isClient && (
        <DeleteResourceDialog
          isOpen={isDeleteDialogOpen}
          resourceName={resourceToDelete ? resourceToDelete.name : ''}
          onClose={() => setIsDeleteDialogOpen(false)}
          onConfirm={() => handleConfirmDelete()}
        />
      )}
    </>
  )
}
