import { ButtonGroup, Button, Typography, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import UpIcon from '@material-ui/icons/ArrowUpward'
import DownIcon from '@material-ui/icons/ArrowDownward'
import FavoriteIcon from '@material-ui/icons/Star'
import NotFavoriteIcon from '@material-ui/icons/StarOutline'
import ResourceFullSwitch from './ResourceFullSwitch'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: '1px solid rgba(0, 0, 0, 0.22)',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
    },
  },
  moveButtons: {
    marginLeft: 'auto',
  },
  favoriteButton: {
    color: theme.palette.accent.main,
    marginRight: theme.spacing(0.5),
  },
  actions: {
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
}))

export default function ClientResourceHeader({
  name,
  isFavorite,
  isInFavoritesSection,
  onRemoveFavorite,
  onAddFavorite,
  isFull,
  onFullSwitchChange,
  isFirst,
  isLast,
  onMoveUp,
  onMoveDown,
}) {
  const classes = useStyles()

  function handleFavoriteButtonClick() {
    if (isFavorite) {
      onRemoveFavorite()
    } else {
      onAddFavorite()
    }
  }

  return (
    <div className={classes.root}>
      <IconButton
        aria-label={isFavorite ? 'unmark as favorite' : 'mark as favorite'}
        size="small"
        className={classes.favoriteButton}
        onClick={handleFavoriteButtonClick}
      >
        {isFavorite ? <FavoriteIcon /> : <NotFavoriteIcon />}
      </IconButton>
      <Typography variant="h3" className={classes.name}>
        {name}
      </Typography>
      <div className={classes.actions}>
        <ResourceFullSwitch
          isFull={isFull}
          onFullSwitchChange={onFullSwitchChange}
          isInFavoritesSection={isInFavoritesSection}
        />
        {isInFavoritesSection && (
          <ButtonGroup color="secondary" className={classes.moveButtons} size="small">
            <Button aria-label="move resource up" disabled={isFirst} onClick={onMoveUp}>
              <UpIcon />
            </Button>
            <Button aria-label="move resource down" disabled={isLast} onClick={onMoveDown}>
              <DownIcon />
            </Button>
          </ButtonGroup>
        )}
      </div>
    </div>
  )
}
