import { ButtonGroup, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import UpIcon from '@material-ui/icons/ArrowUpward'
import DownIcon from '@material-ui/icons/ArrowDownward'
import DeleteIcon from '@material-ui/icons/Delete'
import FinishEditIcon from '@material-ui/icons/Check'
import CancelEditIcon from '@material-ui/icons/Close'
import EditableText from './EditableText'
import ResourceFullSwitch from './ResourceFullSwitch'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: '1px solid rgba(0, 0, 0, 0.22)',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
    },
  },
  textField: {
    width: '100%',
  },
  nameInput: {
    fontSize: '1.5rem',
  },
  actionButtons: {
    marginLeft: 'auto',
  },
}))

export default function NonClientResourceHeader({
  name,
  setName,
  isEditing,
  setIsEditing,
  isFull,
  onFullSwitchChange,
  isNewResource,
  isFirst,
  isLast,
  onMoveUp,
  onMoveDown,
  onDelete,
  handleFinishEditing,
  handleCancelEditing,
}) {
  const classes = useStyles()

  function getResourceActions() {
    if (isNewResource) {
      return getCreateActions()
    }
    if (isEditing) {
      return getEditActions()
    }
    return (
      <ButtonGroup color="secondary" className={classes.actionButtons} size="small">
        <Button aria-label="edit resource" onClick={() => setIsEditing(true)}>
          <EditIcon />
        </Button>
        <Button aria-label="move resource up" disabled={isFirst} onClick={onMoveUp}>
          <UpIcon />
        </Button>
        <Button aria-label="move resource down" disabled={isLast} onClick={onMoveDown}>
          <DownIcon />
        </Button>
        <Button aria-label="delete resource" onClick={onDelete}>
          <DeleteIcon />
        </Button>
      </ButtonGroup>
    )
  }

  function getEditActions() {
    return (
      <ButtonGroup color="secondary" className={classes.actionButtons} size="small">
        <Button aria-label="finish editing resource" disabled={!name} onClick={handleFinishEditing}>
          <FinishEditIcon />
        </Button>
        <Button aria-label="cancel editing resource" disabled={!name} onClick={handleCancelEditing}>
          <CancelEditIcon />
        </Button>
      </ButtonGroup>
    )
  }

  function getCreateActions() {
    return (
      <ButtonGroup color="secondary" className={classes.actionButtons} size="small">
        <Button aria-label="finish editing resource" disabled={!name} onClick={handleFinishEditing}>
          <FinishEditIcon />
        </Button>
        <Button aria-label="delete resource" onClick={onDelete}>
          <DeleteIcon />
        </Button>
      </ButtonGroup>
    )
  }

  return (
    <div className={classes.root}>
      <EditableText
        value={name}
        onChangeValue={(val) => setName(val)}
        isEditing={isEditing}
        typographyProps={{ variant: 'h3', className: classes.name }}
        textFieldProps={{
          className: classes.textField,
          variant: 'outlined',
          placeholder: 'Name',
          inputProps: { className: classes.nameInput },
        }}
      />
      {getResourceActions()}
      <ResourceFullSwitch isFull={isFull} onFullSwitchChange={onFullSwitchChange} />
    </div>
  )
}
