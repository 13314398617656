import { DataGrid } from '@material-ui/data-grid'
import { Link, IconButton } from '@material-ui/core'
import NotesIcon from '@material-ui/icons/DescriptionOutlined'
import { makeStyles } from '@material-ui/core/styles'
import './OverviewTable.css'

const useStyles = makeStyles((theme) => ({
  clientName: {
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
  },
}))

export default function ConnectorOverviewTable({ rows, goToClientTimeline, goToClientNotes }) {
  const classes = useStyles()

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 220,
      renderCell: (params) => (
        <Link
          className={classes.clientName}
          component="button"
          variant="body1"
          onClick={() => goToClientTimeline(params.row.id)}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: 'participantId',
      headerName: 'PID',
      width: 110,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 180,
    },
    {
      field: 'phone',
      headerName: 'Phone',
      width: 150,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 240,
    },
    {
      field: 'groupType',
      headerName: 'Group',
      width: 130,
    },
    {
      field: 'lastCompletedTimepoint',
      headerName: 'Last Completed',
      width: 200,
    },
    {
      field: 'notes',
      headerName: 'Notes',
      width: 130,
      renderCell: (params) => (
        <IconButton
          size="small"
          color="secondary"
          aria-label="go to client notes"
          onClick={() => goToClientNotes(params.row.id)}
        >
          <NotesIcon />
        </IconButton>
      ),
    },
  ]

  return (
    <DataGrid
      classes={{ root: 'overview-table' }}
      rows={rows}
      columns={columns}
      disableColumnSelector
      disableDensitySelector
      disableSelectionOnClick
      hideFooter
      autoHeight
    />
  )
}
