import { useState } from 'react'
import { TextField, Typography, Button, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import FormContainer from '../components/common/FormContainer'
import { useUser } from '../context/UserContext'
import { useApi } from '../context/ApiContext'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.accent.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(2, 0),
  },
  errorList: {
    margin: 0,
    paddingLeft: theme.spacing(2),
  },
}))

export default function Profile() {
  const classes = useStyles()
  const user = useUser()
  const api = useApi()

  const [profile, setProfile] = useState({
    firstName: user.data.firstName,
    lastName: user.data.lastName,
    phone: user.data.phone,
  })

  function canSubmit() {
    return (
      profile.firstName !== user.data.firstName ||
      profile.lastName !== user.data.lastName ||
      profile.phone !== user.data.phone
    )
  }

  async function handleSubmit(e) {
    e.preventDefault()
    const userData = await api.updateUser({ ...user.data, ...profile })
    if (userData) {
      window.location.reload()
    }
  }

  return (
    <FormContainer>
      <Typography component="h1" variant="h4">
        Edit your profile
      </Typography>
      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {user.data.type !== 'client' && (
            <Grid item xs={12}>
              <TextField
                autoFocus
                color="secondary"
                variant="outlined"
                required
                fullWidth
                id="phone"
                label="Phone number"
                value={profile.phone}
                onChange={(e) => setProfile({ ...profile, phone: e.target.value })}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              color="secondary"
              variant="outlined"
              required
              fullWidth
              id="firstName"
              label="First Name"
              value={profile.firstName}
              onChange={(e) => setProfile({ ...profile, firstName: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              color="secondary"
              variant="outlined"
              required
              fullWidth
              id="lastName"
              label="Last Name"
              value={profile.lastName}
              onChange={(e) => setProfile({ ...profile, lastName: e.target.value })}
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="secondary"
          size="large"
          disabled={!canSubmit()}
          className={classes.submit}
        >
          Update
        </Button>
      </form>
    </FormContainer>
  )
}
