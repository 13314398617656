import HousingIcon from '@material-ui/icons/Home'
import EmploymentIcon from '@material-ui/icons/Work'
import CommunityIcon from '@material-ui/icons/PeopleAlt'
import HealthIcon from '@material-ui/icons/LocalHospital'
import TravelIcon from '@material-ui/icons/Explore'
import LifeSkillsIcon from '@material-ui/icons/WbSunny'
import ConnectionIcon from '@material-ui/icons/EmojiPeople'

const GoalType = {
  Housing: 'Housing',
  Employment: 'Employment',
  LifeSkills: 'Life Skills',
  Connection: 'Connection',
  Community: 'Community',
  Health: 'Health & Wellbeing',
  Travel: 'Travel',
  // Other: 'Other',
}

export const GoalTypeIcon = {
  Housing: HousingIcon,
  Employment: EmploymentIcon,
  LifeSkills: LifeSkillsIcon,
  Connection: ConnectionIcon,
  Community: CommunityIcon,
  Health: HealthIcon,
  Travel: TravelIcon,
  // Other: BullseyeArrow,
}

export default GoalType
