import { forwardRef, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Dialog, AppBar, Toolbar, Typography, IconButton, Slide } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useNonClient } from '../../context/NonClientContext'
import { useClient } from '../../context/ClientContext'
import { useUser } from '../../context/UserContext'
import UserType from '../../enums/UserType'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  iframe: {
    border: 'none',
    marginTop: theme.spacing(10),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '100%',
    height: '100%',
  },
  noSurvey: {
    marginTop: theme.spacing(12),
    textAlign: 'center',
    fontSize: '1.5rem',
  },
}))

export default function Survey({ title, onClose, isOpen, url, onSurveyEnd }) {
  const classes = useStyles()
  const nonClient = useNonClient()
  const client = useClient()
  const user = useUser()

  let pid
  if (user.data.type === UserType.Client) {
    pid = client.info.participantId
  } else {
    pid = nonClient.currentClient.participantId
  }

  useEffect(() => {
    function handleSurveyEnd(e) {
      if (e.origin === 'https://foundationssc.qualtrics.com' && e.data === 'closeQSIWindow') {
        onSurveyEnd()
      }
    }
    window.addEventListener('message', handleSurveyEnd)
    return () => window.removeEventListener('message', handleSurveyEnd)
  }, [onSurveyEnd])

  return (
    <Dialog className={classes.root} fullScreen onClose={onClose} open={isOpen} TransitionComponent={Transition}>
      <AppBar className={classes.appBar} color="primary">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {url ? (
        <iframe
          data-test="survey"
          title="survey"
          src={`${url}?pid=${pid}`}
          width="100%"
          height="100%"
          className={classes.iframe}
        ></iframe>
      ) : (
        <Typography className={classes.noSurvey}>Survey not available.</Typography>
      )}
    </Dialog>
  )
}
