import { useState, useRef } from 'react'
import { TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MainContentHeading from '../../common/MainContentHeading'
import { useApi } from '../../../context/ApiContext'
import { useClient } from '../../../context/ClientContext'
import MainContent from '../../common/MainContent'
import UserType from '../../../enums/UserType'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
  },
  subtitle: {
    marginTop: theme.spacing(-3),
    marginBottom: theme.spacing(3),
  },
  textField: {
    width: '100%',
  },
}))

export default function Journal() {
  const classes = useStyles()
  const api = useApi()
  const client = useClient()

  const [isTextFieldFocused, setIsTextFieldFocused] = useState(false)
  const inputRef = useRef()

  async function handleBlur(e) {
    setIsTextFieldFocused(false)
    const updatedClientInfo = await api.updateClientInfo(client.info.id, UserType.Client, {
      journal: inputRef.current.value,
    })
    client.setInfo(updatedClientInfo)
  }

  return (
    <MainContent>
      <MainContentHeading text="Write down some thoughts" />
      <Typography className={classes.subtitle} align="center">
        You can use this space for anything you like - for example, writing down thoughts or reminders. Everything
        written here is confidential - only you can see it.
      </Typography>
      <div className={classes.root}>
        <Typography color="textSecondary">{isTextFieldFocused ? 'Tap away to save' : 'Tap to edit'}</Typography>
        <TextField
          inputRef={inputRef}
          className={classes.textField}
          inputProps={{ style: { fontSize: '1.3rem', lineHeight: '1.8rem' }, 'data-test': 'journal-textfield' }}
          defaultValue={client.info.journal}
          placeholder="Tap to edit..."
          multiline
          variant="outlined"
          color="secondary"
          rows={12}
          maxRows={300}
          onFocus={() => setIsTextFieldFocused(true)}
          onBlur={handleBlur}
        />
      </div>
    </MainContent>
  )
}
