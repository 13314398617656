import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
}))

export default function MainContentHeading({ text, icon = null }) {
  const classes = useStyles()

  return (
    <Typography variant="h1" color="primary" align="center" className={classes.root}>
      {icon}
      {text}
    </Typography>
  )
}
