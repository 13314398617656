import { makeStyles } from '@material-ui/core/styles'
import { AppBar, Tabs as MuiTabs, Tab, useMediaQuery } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '64px',
    [theme.breakpoints.down('sm')]: {
      top: 'auto',
      bottom: 0,
      margin: 0,
    },
  },
  indicator: {
    [theme.breakpoints.down('sm')]: {
      top: 0,
    },
  },
  tabs: {
    backgroundColor: 'white',
  },
  tab: {
    minWidth: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.85rem',
    },
  },
  tabIcon: {
    verticalAlign: 'top',
    marginRight: '6px',
  },
}))

export default function Tabs({ tabIndex, onTabChange, tabData }) {
  const classes = useStyles()
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  function getTab(index, label, Icon) {
    return isSmallScreen ? (
      <Tab
        key={label}
        // className={classes.tab}
        classes={{ root: classes.tab }}
        // label={label}
        icon={<Icon aria-label={label} />}
        id={`tab-${index}`}
        aria-controls={`tab-${index}`}
      />
    ) : (
      <Tab
        key={label}
        classes={{ root: classes.tab }}
        label={
          <div>
            <Icon className={classes.tabIcon} aria-label={label} />
            {label}
          </div>
        }
        id={`tab-${index}`}
        aria-controls={`tab-${index}`}
      />
    )
  }

  return (
    <AppBar className={classes.root} position="fixed" color="default">
      <MuiTabs
        classes={{ root: classes.tabs, indicator: classes.indicator }}
        value={tabIndex}
        onChange={onTabChange}
        indicatorColor="secondary"
        textColor="secondary"
        variant="fullWidth"
        aria-label="navigation tabs"
      >
        {tabData.map((t, i) => getTab(i, t.name, t.icon))}
      </MuiTabs>
    </AppBar>
  )
}
