import { createContext, useContext, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useApi } from './ApiContext'
import { useAuth } from './AuthContext'
import Spinner from '../components/common/Spinner'
import UserType from '../enums/UserType'

const UserContext = createContext()

function UserProvider({ children }) {
  const location = useLocation()
  const auth = useAuth()
  const api = useApi()
  const [userData, setUserData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isRedirecting, setIsRedirecting] = useState(false)

  useEffect(() => {
    async function getUser() {
      // if user is not logged in and on a page that needs auth, redirect to login page
      if (!auth.state.isLoading && !auth.state.isAuthenticated) {
        if (
          location.pathname !== '/auth/login' &&
          location.pathname !== '/auth/registration' &&
          location.pathname !== '/auth/recovery'
        ) {
          window.location.href = '/auth/login'
          setIsRedirecting(true)
        }
        setIsLoading(false)
      }
      // if user is logged in and on a page but there's no user profile data, redirect to profile setup
      if (auth.state.isAuthenticated && !userData) {
        const user = await api.fetchCurrentUser()

        if (user) {
          setUserData(user)
        } else if (
          auth.state.userType !== UserType.Client &&
          !auth.state.isVerified &&
          location.pathname !== '/auth/verification' &&
          location.pathname !== '/auth/logout'
        ) {
          window.location.href = '/auth/verification'
          setIsRedirecting(true)
        } else if (
          (auth.state.isVerified || auth.state.userType === UserType.Client) &&
          location.pathname !== '/profile-setup' &&
          location.pathname !== '/auth/logout'
        ) {
          window.location.href = '/profile-setup'
          setIsRedirecting(true)
        }
        setIsLoading(false)
      }
    }
    getUser()
  }, [api, auth, location, userData])

  if (isLoading || isRedirecting) {
    return <Spinner />
  }

  return <UserContext.Provider value={{ data: userData, setUserData }}>{children}</UserContext.Provider>
}

function useUser() {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider')
  }
  return context
}

export { UserProvider, useUser }
