import { makeStyles } from '@material-ui/core/styles'

export const useGoalDialogStyles = makeStyles((theme) => ({
  root: {},
  appBar: {
    backgroundColor: theme.palette.secondary.main,
  },
  appBarCelebration: {
    backgroundColor: theme.palette.accent.main,
  },
  title: {
    padding: '4px 0',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.2rem',
    },
  },
  titleIcon: {
    marginRight: theme.spacing(1),
  },
  form: {
    marginTop: '78px',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '1024px',
    padding: theme.spacing(1),
  },
  field: {
    marginBottom: theme.spacing(2),
  },
  typeSelect: {
    display: 'flex',
    alignItems: 'center',
    height: '1.1876em',
  },
  typeSelectIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  dateDueWrapper: {
    width: '100%',
    display: 'flex',
  },
  dateDue: {
    width: '100%',
  },
  dateDueSwitchFormControlLabel: {
    width: '170px',
    margin: 0,
  },
  submitButtonCelebration: {
    backgroundColor: theme.palette.accent.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.accent.hover,
    },
  },
  completeButton: {
    marginTop: theme.spacing(1),
  },
  moveBackToGoalsButton: {
    marginTop: theme.spacing(1),
    color: theme.palette.accent.main,
    borderColor: theme.palette.accent.border,
    '&:hover': {
      borderColor: theme.palette.accent.main,
      backgroundColor: theme.palette.accent.light,
    },
  },
  deleteButton: {
    marginTop: theme.spacing(1),
    color: theme.palette.red.main,
    borderColor: theme.palette.red.border,
    '&:hover': {
      borderColor: theme.palette.red.main,
      backgroundColor: theme.palette.red.light,
    },
  },
}))
