export const CONTACT_ATTEMPT_SURVEY_URL = 'https://foundationssc.qualtrics.com/jfe/form/SV_b1VvP7FHSAFO8Hc'

export const SURVEY_MONTH_OFFSETS = {
  '1 month survey': 1,
  '2 month survey': 2,
  '3 month survey': 3,
  '6 month survey': 6,
  '9 month survey': 9,
  '12 month survey': 12,
}

export const NETWORK_ERROR_MESSAGE =
  'There was a network error. Please make sure you are connected to the internet and try again.'

export const GENERIC_ERROR_MESSAGE = 'There was an error. Please try again later.'

export const NOT_FOUND_ERROR_MESSAGE = 'The request or page was not found.'

export const GOAL_SETTING_AFFIRMATIONS = [
  'I will be okay, everything will work out.',
  "It's a journey and I can take one step at a time...",
  "I'm not alone, I can ask for help and find support.",
  'Someone believes in me.',
  'I can keep going.',
  'I can follow my own path.',
]

export const APPROVED_RESEARCHER_EMAILS = [
  'max+researcher@forsocialchange.org',
  'jiayingz@psych.ubc.ca',
  'ryandwyer5@gmail.com',
  'kaityavery@gmail.com',
  'kennethong25@gmail.com',
  'upkar@oxusmachineworks.com'
]