import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import EditableText from '../common/EditableText'
import ClientInfoFieldWrapper from './ClientInfoFieldWrapper'

const useStyles = makeStyles((theme) => ({
  valueTextField: {
    width: '100%',
  },
  valueInput: {
    fontSize: '1.3rem',
  },
  valueTypography: {
    fontSize: '1.3rem',
  },
  editButton: {
    marginLeft: 'auto',
    color: theme.palette.secondary.main,
  },
}))

export default function ClientInfoTextField({ label, initialValue, isEditable, onUpdateField }) {
  const classes = useStyles()
  const [isEditing, setIsEditing] = useState(false)
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  function handleFinishEditingText() {
    setIsEditing(false)
    onUpdateField(value)
  }

  return (
    <ClientInfoFieldWrapper label={label}>
      <EditableText
        autofocus
        value={value}
        onChangeValue={(val) => setValue(val)}
        isEditing={isEditing}
        onFinishEditing={handleFinishEditingText}
        textFieldProps={{ className: classes.valueTextField, inputProps: { className: classes.valueInput } }}
        typographyProps={{ className: classes.valueTypography }}
      />
      {isEditable && (
        <IconButton
          size="small"
          className={classes.editButton}
          onClick={() => setIsEditing(true)}
          color="secondary"
          aria-label="edit"
        >
          <EditIcon />
        </IconButton>
      )}
    </ClientInfoFieldWrapper>
  )
}
