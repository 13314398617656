import UserType from '../../enums/UserType'
import TextFieldDialog from './TextFieldDialog'

export default function TimepointProtocol({ onClose, onSave, isOpen, timepointName, protocol, userType }) {
  return (
    <TextFieldDialog
      onClose={onClose}
      onSave={onSave}
      isOpen={isOpen}
      name={`Protocol for ${timepointName}`}
      initialValue={protocol}
      isEditable={userType === UserType.Researcher}
    />
  )
}
