import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Paper } from '@material-ui/core'
import { getLongFormattedDate, getFormattedTime, isDateToday } from '../../utils/helpers'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    textAlign: 'center',
    padding: theme.spacing(2),
    background: '#ebf0ff',
  },
  today: {
    border: '4px solid #cc3c79',
  },
  header: {
    fontSize: '1rem',
    // fontWeight: 700,
    textTransform: 'uppercase',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
    },
  },
  name: {
    fontSize: '2.25rem',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.9rem',
      lineHeight: 1.2,
    },
  },
  date: {
    fontSize: '1.2rem',
    lineHeight: 1.4,
    //fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1rem',
    },
  },
  time: {
    fontSize: '1.2rem',
    lineHeight: 1.4,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1rem',
    },
  },
}))

export default function AppointmentReminder({ name, date, address, isPhoneCall }) {
  const classes = useStyles()
  const isToday = isDateToday(date)
  const rootClassName = clsx(classes.root, isToday && classes.today)
  let header
  if (isPhoneCall) {
    header = isToday ? 'Phone call today!' : 'Upcoming phone call'
  } else {
    header = isToday ? 'Appointment today!' : 'Upcoming appointment'
  }

  return (
    <Paper className={rootClassName} variant="outlined" square data-test="appointment-reminder">
      <Typography className={classes.header}>{header}</Typography>
      <Typography className={classes.name}>{name}</Typography>
      <Typography className={classes.date}>{isToday ? 'TODAY' : getLongFormattedDate(date)}</Typography>
      <Typography className={classes.time}>{getFormattedTime(date)}</Typography>
      {!isPhoneCall && <Typography className={classes.time}>{address}</Typography>}
    </Paper>
  )
}
