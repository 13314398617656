import axios from 'axios'
import appConfig from '../config'
import { NETWORK_ERROR_MESSAGE, NOT_FOUND_ERROR_MESSAGE, GENERIC_ERROR_MESSAGE } from '../constants'

export default class ApiService {
  handleError

  constructor(handleError) {
    this.handleError = handleError
  }

  onError(err) {
    let errMsg = GENERIC_ERROR_MESSAGE
    if (err.message.includes('404')) {
      errMsg = NOT_FOUND_ERROR_MESSAGE
    } else if (err.message.includes('Network Error')) {
      errMsg = NETWORK_ERROR_MESSAGE
    } else if (err.response?.data?.error?.message) {
      errMsg = err.response.data.error.message
    }
    this.handleError(errMsg)
  }

  async get(url, config) {
    try {
      const res = await axios(url, config)
      return res.data
    } catch (err) {
      this.onError(err)
    }
  }

  async post(url, body, config) {
    try {
      const res = await axios.post(url, body, config)
      return res.data
    } catch (err) {
      this.onError(err)
    }
  }

  async put(url, body, config) {
    try {
      const res = await axios.put(url, body, config)
      return res.data
    } catch (err) {
      this.onError(err)
    }
  }

  async delete(url, config) {
    try {
      const res = await axios.delete(url, config)
      return res.data
    } catch (err) {
      this.onError(err)
    }
  }

  async fetchConnectors() {
    return this.get(`${appConfig.backendURL}/connectors`, { withCredentials: true })
  }

  async fetchCurrentUser() {
    return this.get(`${appConfig.backendURL}/currentUser`, { withCredentials: true })
  }

  async fetchUser(id) {
    return this.get(`${appConfig.backendURL}/user?id=${id}`, { withCredentials: true })
  }

  async createUser(userData, startDate) {
    return this.post(`${appConfig.backendURL}/user`, { ...userData, startDate }, { withCredentials: true })
  }

  async updateUser(userData) {
    return this.put(`${appConfig.backendURL}/user`, userData, { withCredentials: true })
  }

  async fetchAllClients() {
    // PUT request because lastAutomaticallyDelayedTimepointId may be updated
    return this.put(`${appConfig.backendURL}/allClients`, null, { withCredentials: true })
  }

  async fetchConnectorsClients(userId) {
    // PUT request because lastAutomaticallyDelayedTimepointId may be updated
    return this.put(`${appConfig.backendURL}/connectorsClients`, { userId }, { withCredentials: true })
  }

  async fetchClientInfo(userId, currentUserType) {
    return this.get(`${appConfig.backendURL}/client-info?userId=${userId}&userType=${currentUserType}`, {
      withCredentials: true,
    })
  }

  async updateClientInfo(clientInfoId, currentUserType, propertiesToUpdate) {
    return this.put(
      `${appConfig.backendURL}/client-info`,
      { clientInfoId, currentUserType, ...propertiesToUpdate },
      { withCredentials: true }
    )
  }

  async fetchClientTimepoints(clientInfoId, isForClient) {
    return this.get(
      `${appConfig.backendURL}/client-timepoints?clientInfoId=${clientInfoId}&isForClient=${isForClient}`,
      {
        withCredentials: true,
      }
    )
  }

  async updateJournal(clientInfoId, text) {
    return this.put(`${appConfig.backendURL}/journal`, { newJournal: text, clientInfoId }, { withCredentials: true })
  }

  async fetchContacts(clientInfoId) {
    const connectorInfo = await this.get(`${appConfig.backendURL}/connector?clientInfoId=${clientInfoId}`, {
      withCredentials: true,
    })
    return [
      {
        ...connectorInfo,
        title: 'Connector',
        description: 'If you have any questions or concerns, please contact:',
      },
      {
        firstName: 'Dr. Jiaying',
        lastName: 'Zhao',
        phone: '609-865-4765',
        email: 'jiayingz@psych.ubc.ca',
        title: 'Researcher',
        description: 'If you have any questions about this study, please contact:',
      },
      {
        firstName: 'Research Participant Complaint Line',
        lastName: '',
        phone: '604-822-8598',
        tollFreePhone: '1-877-822-8598',
        email: 'RSIL@ors.ubc.ca',
        title: 'UBC Office of Research Ethics',
        description:
          'If you have any concerns or complaints about your rights as a research participant or your experiences while participating in this study, please contact:',
      },
    ]
  }

  async fetchResources(category) {
    return this.get(`${appConfig.backendURL}/resources?category=${category}`, { withCredentials: true })
  }

  async createResource(params) {
    return this.post(`${appConfig.backendURL}/resource`, params, { withCredentials: true })
  }

  async deleteResource(resourceId) {
    return this.delete(`${appConfig.backendURL}/resource?id=${resourceId}`, { withCredentials: true })
  }

  async updateResource(id, params) {
    return this.put(`${appConfig.backendURL}/resource`, { id, ...params }, { withCredentials: true })
  }

  async switchResourceSequenceIndices(resourceId1, resourceId2) {
    return this.put(`${appConfig.backendURL}/switchResources`, { resourceId1, resourceId2 }, { withCredentials: true })
  }

  async fetchClientResources(clientInfoId) {
    return this.get(`${appConfig.backendURL}/clientResources?clientInfoId=${clientInfoId}`, {
      withCredentials: true,
    })
  }

  async addClientResource(clientInfoId, resourceId) {
    return this.post(`${appConfig.backendURL}/clientResource`, { clientInfoId, resourceId }, { withCredentials: true })
  }

  async removeClientResource(clientInfoId, resourceId) {
    return this.delete(`${appConfig.backendURL}/clientResource?clientInfoId=${clientInfoId}&resourceId=${resourceId}`, {
      withCredentials: true,
    })
  }

  async switchClientResourceSequenceIndices(clientInfoId, resourceId1, resourceId2) {
    return this.put(
      `${appConfig.backendURL}/switchClientResources`,
      { clientInfoId, resourceId1, resourceId2 },
      { withCredentials: true }
    )
  }

  async updateClientTimepoint(clientInfoId, timepointId, propertiesToUpdate) {
    return this.put(
      `${appConfig.backendURL}/client-timepoint`,
      { clientInfoId, timepointId, ...propertiesToUpdate },
      { withCredentials: true }
    )
  }

  async updateTimepoint(timepointId, propertiesToUpdate) {
    return this.put(
      `${appConfig.backendURL}/timepoint`,
      { timepointId, ...propertiesToUpdate },
      { withCredentials: true }
    )
  }

  async fetchMetrics() {
    return this.get(`${appConfig.backendURL}/metrics`, { withCredentials: true })
  }

  async fetchGoals(clientInfoId) {
    return this.get(`${appConfig.backendURL}/goals?clientInfoId=${clientInfoId}`, { withCredentials: true })
  }

  async createGoal(clientInfoId, params) {
    return this.post(`${appConfig.backendURL}/goal`, { clientInfoId, params }, { withCredentials: true })
  }

  async updateGoal(clientInfoId, goalId, params) {
    return this.put(`${appConfig.backendURL}/goal`, { clientInfoId, goalId, params }, { withCredentials: true })
  }

  async deleteGoal(clientInfoId, goalId) {
    return this.delete(`${appConfig.backendURL}/goal?clientInfoId=${clientInfoId}&goalId=${goalId}`, {
      withCredentials: true,
    })
  }
}
