import { createContext, useContext, useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import ApiService from '../api/ApiService'
import SnackbarCloseButton from '../components/common/SnackbarCloseButton'

const ApiContext = createContext()

function ApiProvider({ children }) {
  const { enqueueSnackbar } = useSnackbar()

  function handleError(errorMsg) {
    enqueueSnackbar(errorMsg, {
      variant: 'error',
      persist: true,
      action: (key) => <SnackbarCloseButton id={key} />,
    })
  }

  return <ApiContext.Provider value={new ApiService(handleError)}>{children}</ApiContext.Provider>
}

function useApi() {
  const context = useContext(ApiContext)
  if (context === undefined) {
    throw new Error('useApi must be used within a ApiProvider')
  }
  return context
}

export { ApiProvider, useApi }
