import { makeStyles } from '@material-ui/core/styles'
import { Typography, Select, FormControl, MenuItem } from '@material-ui/core'
import { getFullName } from '../../utils/helpers'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  select: {
    marginTop: theme.spacing(0.5),
    fontSize: '1.3rem',
  },
  label: {
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    fontSize: '1rem',
  },
  value: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
}))

export default function ClientConnectorField({ selectedConnectorUserId, connectors, onChangeConnector }) {
  const classes = useStyles()

  if (!connectors) {
    return null
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.label}>Connector</Typography>
      <div className={classes.value}>
        <FormControl variant="outlined" size="small" color="secondary">
          <Select
            value={connectors.find((c) => c.id === selectedConnectorUserId)}
            onChange={(e) => onChangeConnector(e.target.value)}
            size="small"
            className={classes.select}
            data-test="client-info-select"
          >
            {connectors.map((connector) => (
              <MenuItem key={connector.id} value={connector}>
                {getFullName(connector)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  )
}
