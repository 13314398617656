import clsx from 'clsx'
import { Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PhoneIcon from '@material-ui/icons/Phone'
import EmailIcon from '@material-ui/icons/Email'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textAlign: 'center',
    background: '#ebf0ff',
  },
  description: {
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: '1rem',
    marginBottom: theme.spacing(2),
  },
  contactInfo: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    margin: 'auto',
    fontSize: '1.25rem',
    whitespace: 'pre-wrap',
  },
  email: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.05rem',
    },
  },
  contactIcon: {
    marginRight: theme.spacing(0.5),
    color: theme.palette.secondary.main,
  },
}))

export default function Contact({ firstName, lastName, title, phone, tollFreePhone, email, description }) {
  const classes = useStyles()

  return (
    <Paper className={classes.root} elevation={2}>
      <Typography className={classes.description}>{description}</Typography>

      <Typography variant="h2" className={classes.name}>
        {`${firstName} ${lastName}`}
      </Typography>

      <Typography variant="h3" className={classes.title}>
        {title}
      </Typography>

      <Typography className={classes.contactInfo}>
        <PhoneIcon className={classes.contactIcon} />
        <a href={`tel:${phone}`}>{phone}</a>
      </Typography>

      {tollFreePhone && (
        <Typography className={classes.contactInfo}>
          <PhoneIcon className={classes.contactIcon} />
          <a href={`tel:${tollFreePhone}`}>{tollFreePhone}</a> (toll-free)
        </Typography>
      )}

      <Typography className={classes.contactInfo}>
        <EmailIcon className={clsx(classes.contactIcon, classes.email)} />
        {email}
      </Typography>
    </Paper>
  )
}
