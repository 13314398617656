import dayjs from 'dayjs'
import IsToday from 'dayjs/plugin/isToday'
import CustomParseFormat from 'dayjs/plugin/customParseFormat'
import Utc from 'dayjs/plugin/utc'
import IsTomorrow from 'dayjs/plugin/isTomorrow'
import UserType from '../enums/UserType'
import appConfig from '../config'
import { APPROVED_RESEARCHER_EMAILS } from '../constants'

dayjs.extend(Utc)
dayjs.extend(IsToday)
dayjs.extend(CustomParseFormat)
dayjs.extend(IsTomorrow)

const format = 'ddd MMM D YYYY hh:mm:ss'

// converts from utc to local timezone
export function getJsDateFromPostgresTimestamp(timestamp) {
  const x = timestamp === null ? null : dayjs.utc(timestamp.replace('T', ' ').replace('Z', '')).local()
  return x
}

export function getShortFormattedDate(date) {
  return dayjs(date, format).format('MMM D')
}

export function getExtraShortFormattedDate(date) {
  return dayjs(date, format).format('MM/DD')
}

export function getFormattedTime(date) {
  return dayjs(date, format).format('h:mma')
}

export function getDaysBetweenTodayAndDate(date) {
  return dayjs(date).diff(dayjs(), 'day', true)
}

export function getLongFormattedDate(date) {
  return dayjs(date, format).format('dddd, MMMM D')
}

export function isDateToday(date) {
  return dayjs(date, format).isToday()
}

export function isDateTomorrow(date) {
  //const tomorrow = dayjs().add(1, 'day')
  return dayjs(date, format).isTomorrow()
}

export function isDateBeforeToday(date) {
  const startOfToday = getStartOfDay(dayjs())
  const startOfDate = getStartOfDay(date)
  return startOfToday.diff(startOfDate) > 0
}

export function isDateBeforeTomorrow(date) {
  const startOfTomorrow = getStartOfDay(dayjs().add(1, 'days'))
  const startOfDate = getStartOfDay(date)
  return startOfTomorrow.diff(startOfDate) > 0
}

export function addDaysToDate(days, date) {
  return dayjs(date, format).add(days, 'days')
}

export function addMonthsToDate(months, date) {
  // if it's 30 or 31, consider it the end of the month
  return dayjs(date, format).add(months, 'months')
}

export function getStartOfDay(date) {
  return dayjs(date, format).startOf('day')
}

export function getFullName(user) {
  return `${user.firstName} ${user.lastName}`
}

export function isEmailApproved(email, userType) {
  if (!email || appConfig.environment !== 'production') return true

  if (userType === UserType.Researcher) {
    return APPROVED_RESEARCHER_EMAILS.includes(email) || email.endsWith('@forsocialchange.org')
  }

  if (userType === UserType.Connector) {
    return email.endsWith('@forsocialchange.org')
  }

  return true
}

export function updateServiceWorkerRegistrations() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (let registration of registrations) {
        registration.update()
      }
    })
  }
}
