import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  count: {
    fontSize: '3rem',
    fontWeight: '700',
  },
  label: {
    fontSize: '1.2rem',
  },
}))

export default function MetricCount({ label, count }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography className={classes.count}>{count}</Typography>
      <Typography className={classes.label}>{label}</Typography>
    </div>
  )
}
