import { Typography, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MainContentHeading from '../../common/MainContentHeading'
import MainContent from '../../common/MainContent'
import Resources from '../../common/Resources'

const useStyles = makeStyles((theme) => ({
  subtitle: {
    margin: `0 auto ${theme.spacing(3)}px auto`,
    fontSize: '1.25rem',
    maxWidth: '1000px',
  },
  contactsLink: {
    marginTop: '-6px',
    fontSize: '1.25rem',
    textDecoration: 'underline',
    color: theme.palette.secondary.main,
  },
}))

export default function ClientResources({ onTabChange }) {
  const classes = useStyles()

  return (
    <MainContent>
      <MainContentHeading text="My resources" />
      <Typography className={classes.subtitle} align="center">
        You are not alone, and we are here to help. Below you can find multiple resources and supports in your
        community. If you can't find what you need,{' '}
        <Link className={classes.contactsLink} component="button" variant="body1" onClick={() => onTabChange(2)}>
          let your connector know.
        </Link>
      </Typography>
      <Resources />
    </MainContent>
  )
}
