import { useState, useEffect, useCallback } from 'react'
import { Tabs, Tab, AppBar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import TabPanel from './GoalSettingTabPanel'
import Goals from './Goals'
import Celebrations from './Celebrations'
import { useApi } from '../../context/ApiContext'
import { getJsDateFromPostgresTimestamp } from '../../utils/helpers'
import { PartyPopper, ImageFilterHdr } from 'mdi-material-ui'
import { useSnackbar } from 'notistack'
import SnackbarCloseButton from '../common/SnackbarCloseButton'

const useStyles = makeStyles((theme) => ({
  appBar: {
    marginTop: '112px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '64px',
    },
  },
  tab: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem',
      lineHeight: 1.9,
    },
  },
  tabIcon: {
    verticalAlign: 'top',
    marginRight: '6px',
    [theme.breakpoints.down('xs')]: {
      marginRight: '4px',
    },
  },
}))

export default function GoalSetting({ clientInfoId }) {
  const classes = useStyles()
  const api = useApi()
  const { enqueueSnackbar } = useSnackbar()

  const [tabIndex, setTabIndex] = useState(0)
  const [goals, setGoals] = useState([])

  const formatGoalDates = useCallback((_goals) => {
    _goals.forEach((g) => {
      g.dateSet = getJsDateFromPostgresTimestamp(g.dateSet)
      g.dateDue = getJsDateFromPostgresTimestamp(g.dateDue)
    })
    return _goals
  }, [])

  useEffect(() => {
    async function fetchGoals() {
      const initialGoals = await api.fetchGoals(clientInfoId)
      const formattedGoals = formatGoalDates(initialGoals)
      setGoals(formattedGoals)
    }
    fetchGoals()
  }, [api, clientInfoId, formatGoalDates])

  async function handleAddGoal(params) {
    const updatedGoals = await api.createGoal(clientInfoId, params)
    if (updatedGoals) {
      const formattedGoals = formatGoalDates(updatedGoals)
      setGoals(formattedGoals)
    }
  }

  async function handleUpdateGoal(goalId, params, successMsg = 'Your goal was updated.') {
    const updatedGoals = await api.updateGoal(clientInfoId, goalId, params)
    if (updatedGoals) {
      const formattedGoals = formatGoalDates(updatedGoals)
      setGoals(formattedGoals)
      enqueueSnackbar(successMsg, {
        variant: 'success',
        persist: true,
        action: (key) => <SnackbarCloseButton id={key} />,
      })
    }
  }

  async function handleDeleteGoal(goalId, isDeletingCelebration) {
    const updatedGoals = await api.deleteGoal(clientInfoId, goalId)
    if (updatedGoals) {
      const formattedGoals = formatGoalDates(updatedGoals)
      setGoals(formattedGoals)
      const successMsg = isDeletingCelebration
        ? 'Your celebration was deleted.'
        : "It's okay, you'll get there! It's all a process."
      enqueueSnackbar(successMsg, {
        variant: 'success',
        persist: true,
        action: (key) => <SnackbarCloseButton id={key} />,
      })
    }
  }

  return (
    <>
      <AppBar className={classes.appBar} position="fixed" color="inherit">
        <Tabs
          value={tabIndex}
          onChange={(e, index) => setTabIndex(index)}
          aria-label="goal setting tabs"
          indicatorColor="secondary"
          textColor="secondary"
          variant="fullWidth"
          //TabIndicatorProps={{ style: { backgroundColor: '#CC3C79' } }}
        >
          <Tab
            classes={{ root: classes.tab }}
            label={
              <div>
                <ImageFilterHdr className={classes.tabIcon} />
                Goals
              </div>
            }
            aria-label="Goals"
          />
          <Tab
            classes={{ root: classes.tab }}
            label={
              <div>
                <PartyPopper className={classes.tabIcon} />
                Celebrations
              </div>
            }
            aria-label="Celebrations"
          />
        </Tabs>
      </AppBar>
      <TabPanel currentIndex={tabIndex} index={0}>
        <Goals
          items={goals.filter((g) => !g.isComplete && g.type)}
          onAddGoal={handleAddGoal}
          onUpdateGoal={handleUpdateGoal}
          onDeleteGoal={handleDeleteGoal}
        />
      </TabPanel>
      <TabPanel currentIndex={tabIndex} index={1}>
        <Celebrations
          items={goals.filter((g) => g.isComplete || !g.type)}
          onAddGoal={handleAddGoal}
          onUpdateGoal={handleUpdateGoal}
          onDeleteGoal={handleDeleteGoal}
        />
      </TabPanel>
    </>
  )
}
