import { useState } from 'react'
import { Paper, Typography, Grid, Button, Select, FormControl, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MainContentHeading from '../common/MainContentHeading'
import { useApi } from '../../context/ApiContext'
import { useUser } from '../../context/UserContext'
import { useNonClient } from '../../context/NonClientContext'
import MainContent from '../common/MainContent'
import UserType from '../../enums/UserType'
import ClientInfoEditableText from './ClientInfoEditableText'
import ClientInfoSelect from './ClientInfoSelect'
import ClientConnectorField from './ClientConnectorField'
import ClientInfoFieldWrapper from './ClientInfoFieldWrapper'
import { contactMethods, contactTimes, genders, locations } from './clientInfoValues'
import GroupType, { getGroupTypeString } from '../../enums/GroupType'
import { getFullName } from '../../utils/helpers'
import Survey from '../common/Survey'
import { CONTACT_ATTEMPT_SURVEY_URL } from '../../constants'

const contactFieldLabels = {
  firstName: 'First name',
  lastName: 'Last name',
  phone: 'Primary phone',
  secondaryPhone: 'Secondary phone(s)',
  email: 'Primary email (used for app sign-in)',
  secondaryEmail: 'Secondary email',
}

const useStyles = makeStyles((theme) => ({
  section: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  sectionHeader: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  sectionName: {
    marginBottom: theme.spacing(2),
  },
  setSurveyUrlButton: {
    marginLeft: 'auto',
  },
  logContactAttemptButton: {
    marginLeft: 'auto',
  },
}))

export default function ClientInfo() {
  const classes = useStyles()
  const api = useApi()
  const user = useUser()
  const nonClient = useNonClient()
  const client = nonClient.currentClient
  const [isSurveyOpen, setIsSurveyOpen] = useState(false)

  async function handleUpdateField(field, value) {
    const updatedClientInfo = await api.updateClientInfo(client.id, user.data.type, {
      [field]: value,
    })
    console.log('updatedClientInfo: ', updatedClientInfo);
    nonClient.updateClient(updatedClientInfo)
  }

  return (
    <MainContent>
      <MainContentHeading text="Client info" />
      <Paper className={classes.section} variant="outlined">
        <div className={classes.sectionHeader}>
          <Typography variant="h2" className={classes.sectionName}>
            Contact
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            className={classes.logContactAttemptButton}
            onClick={() => setIsSurveyOpen(true)}
          >
            Log a contact attempt
          </Button>
        </div>
        <Grid container spacing={4}>
          {Object.keys(contactFieldLabels).map((field) => (
            <Grid item xs={12} sm={12} md={6} key={field}>
              <ClientInfoEditableText
                label={contactFieldLabels[field]}
                initialValue={client[field]}
                isEditable={field !== 'email'}
                onUpdateField={(val) => handleUpdateField(field, val)}
              />
            </Grid>
          ))}
          <Grid item xs={12} sm={12} md={6}>
            <ClientInfoSelect
              label="Preferred contact method"
              initialValue={client.preferredContactMethod}
              options={contactMethods}
              onUpdateField={(val) => handleUpdateField('preferredContactMethod', val)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ClientInfoSelect
              label="Best time to contact"
              initialValue={client.preferredContactTime}
              options={contactTimes}
              onUpdateField={(val) => handleUpdateField('preferredContactTime', val)}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper className={classes.section} variant="outlined">
        <Typography variant="h2" className={classes.sectionName}>
          Study
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={6}>
            {/* TODO: Refactor this into it's own component */}
            <ClientInfoFieldWrapper label={"Group Type"}>
              <div className={classes.selectWrapper}>
                <FormControl variant="outlined" size="small" className={classes.selectFormControl} color="secondary">
                  <Select
                    value={client.groupType}
                    onChange={(e) => handleUpdateField('groupType', e.target.value)}
                    size="small"
                    className={classes.select}
                    data-test="client-info-select"
                  >
                    {Object.values(GroupType).map((s) => (
                      <MenuItem key={s} value={s}>
                        {getGroupTypeString(s)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </ClientInfoFieldWrapper>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            {user.data.type === UserType.Researcher ? (
              <ClientConnectorField
                selectedConnectorUserId={client.connectorUserId}
                connectors={nonClient.connectors}
                onChangeConnector={(c) => handleUpdateField('connectorUserId', c.id)}
              />
            ) : (
              <ClientInfoEditableText label="Connector" initialValue={getFullName(user.data)} />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ClientInfoEditableText label="PID" initialValue={client.participantId} />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ClientInfoSelect
              label="Hub"
              initialValue={client.location}
              options={locations}
              onUpdateField={(val) => handleUpdateField('location', val)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ClientInfoEditableText
              label="Age"
              initialValue={client.age}
              isEditable
              onUpdateField={(val) => handleUpdateField('age', val)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ClientInfoSelect
              label="Gender"
              initialValue={client.gender}
              options={genders}
              onUpdateField={(val) => handleUpdateField('gender', val)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ClientInfoEditableText label="Ethnicity" initialValue={client.ethnicity} />
          </Grid>
        </Grid>
      </Paper>
      <Survey
        isOpen={isSurveyOpen}
        title="Log a contact attempt"
        onClose={() => setIsSurveyOpen(false)}
        onSurveyEnd={() => {}}
        url={CONTACT_ATTEMPT_SURVEY_URL}
      />
    </MainContent>
  )
}
