import { TextField, Typography, Button, Grid, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import useAuthSnackbars from './useAuthSnackbars'
import FormContainer from '../common/FormContainer'

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  description: {
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(2, 0),
  },
  msgList: {
    margin: 0,
    paddingLeft: theme.spacing(2),
  },
}))

export default function AccountRecovery({ formAction, csrfToken, errorMsgs, infoMsgs }) {
  const classes = useStyles()
  useAuthSnackbars({ errorMsgs, infoMsgs })

  return (
    <FormContainer>
      <Typography component="h1" variant="h4">
        Account recovery
      </Typography>
      <Typography className={classes.description}>
        A one-time link will be sent to your email that will sign you in and let you reset your password.
      </Typography>
      <form className={classes.form} noValidate method="POST" action={formAction}>
        <TextField
          color="secondary"
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email address"
          name="email"
          autoComplete="email"
          autoFocus
          inputProps={{ autoCapitalize: 'none' }}
        />
        <input type="hidden" value={csrfToken} readOnly name="csrf_token" required />
        <Button type="submit" fullWidth variant="contained" size="large" color="secondary" className={classes.submit}>
          Send recovery link
        </Button>
        <Grid container>
          <Grid item xs>
            <Link href="/auth/login" variant="body2" color="secondary">
              Back to login
            </Link>
          </Grid>
          <Grid item>
            <Link href="/auth/registration" variant="body2" color="secondary">
              Don't have an account? Sign Up
            </Link>
          </Grid>
        </Grid>
      </form>
    </FormContainer>
  )
}
