import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Paper, Typography } from '@material-ui/core'
import Spinner from '../components/common/Spinner'
import { useApi } from '../context/ApiContext'
import MetricCount from '../components/Metrics/MetricCount'
import MainContent from '../components/common/MainContent'
import MainContentHeading from '../components/common/MainContentHeading'
import MetricBar from '../components/Metrics/MetricBar'

const useStyles = makeStyles((theme) => ({
  metrics: {},

  section: {
    padding: `${theme.spacing(3)}px ${theme.spacing(1)}px`,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  sectionName: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  metricCounts: {
    display: 'flex',
    justifyContent: 'space-evenly',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  metricBars: {
    padding: `${theme.spacing(2)}px`,
  },
}))

export default function Metrics() {
  const classes = useStyles()
  const api = useApi()

  const [metrics, setMetrics] = useState(null)

  useEffect(() => {
    async function getMetrics() {
      const res = await api.fetchMetrics()
      setMetrics(res)
    }

    getMetrics()
    // eslint-disable-next-line
  }, [])

  if (!metrics) {
    return <Spinner />
  }

  const maxLocationCount = Math.max(...Object.values(metrics.locationCounts))

  // bar value is a percentage
  function normaliseForBarValue(count) {
    return (count * 100) / maxLocationCount
  }

  const longestLocationNameWidth = Math.max(Object.keys(metrics.locationCounts).map((name) => name.length)) * 10

  return (
    <MainContent>
      <MainContentHeading text="Project metrics" />
      <Grid container className={classes.metrics} spacing={2}>
        <Grid item xs={12} md={6}>
          <Paper variant="outlined" className={classes.section}>
            <Typography variant="h2" className={classes.sectionName}>
              Summary
            </Typography>
            <div className={classes.metricCounts}>
              <MetricCount count={metrics.totalClientCount} label="Clients in the project" />
              <MetricCount count={metrics.completedCashTransfersCount} label="Cash transfers made" />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper variant="outlined" className={classes.section}>
            <Typography variant="h2" className={classes.sectionName}>
              Client Status
            </Typography>
            <div className={classes.metricCounts}>
              <MetricCount count={metrics.activeClientCount} label="Active" />
              <MetricCount count={metrics.delayedClientCount} label="Delayed" />
              <MetricCount count={metrics.inactiveClientCount} label="Inactive" />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper variant="outlined" className={classes.section}>
            <Typography variant="h2" className={classes.sectionName}>
              Client Progress
            </Typography>
            <div className={classes.metricCounts}>
              {Object.keys(metrics.completedTimepointCounts).map((timepointName) => (
                <MetricCount
                  key={timepointName}
                  count={metrics.completedTimepointCounts[timepointName]}
                  label={timepointName}
                />
              ))}
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper variant="outlined" className={classes.section}>
            <Typography variant="h2" className={classes.sectionName}>
              Hub Distribution
            </Typography>
            <div className={classes.metricBars}>
              {Object.keys(metrics.locationCounts).map((locationName) => (
                <MetricBar
                  key={locationName}
                  count={metrics.locationCounts[locationName]}
                  barValue={normaliseForBarValue(metrics.locationCounts[locationName])}
                  label={locationName}
                  labelWidth={longestLocationNameWidth}
                />
              ))}
            </div>
          </Paper>
        </Grid>
      </Grid>
    </MainContent>
  )
}
