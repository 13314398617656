import { makeStyles } from '@material-ui/core/styles'
import {
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
} from '@material-ui/lab'
import { Paper, Typography, IconButton } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import DiyIcon from '@material-ui/icons/PhoneAndroid'
import PhoneIcon from '@material-ui/icons/Phone'
import InPersonIcon from '@material-ui/icons/PeopleAlt'
import InfoIcon from '@material-ui/icons/RoomOutlined'
import { getShortFormattedDate, getFormattedTime } from '../../utils/helpers'
import TimepointType from '../../enums/TimepointType'
import { ModalType } from './Timeline'
import TimepointMenu from './TimepointMenu'

const useStyles = makeStyles((theme) => ({
  root: {
    //padding: '6px 0',
    minHeight: '90px',
    width: '460px',
    '@media (max-width:480px)': {
      width: '100%',
    },
  },
  dateTime: {
    flex: 'unset',
    width: '88px',
    paddingLeft: 0,
    marginTop: theme.spacing(-1.5),
    '@media (max-width:480px)': {
      paddingRight: theme.spacing(1.5),
    },
  },
  onlyDate: {
    marginTop: theme.spacing(1.5),
  },
  dot: {
    width: '24px',
    height: '24px',
    borderWidth: '3px',
    padding: 0,
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.accent.main,
  },
  check: {
    width: '36px',
    height: '36px',
    color: theme.palette.accent.main,
  },
  mainInfo: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    marginTop: theme.spacing(-1),
    '@media (max-width:480px)': {
      padding: `${theme.spacing(1)}px ${theme.spacing(0.5)}px`,
    },
  },
  mainInfoWrapper: {
    paddingRight: 0,
    '@media (max-width:480px)': {
      paddingLeft: theme.spacing(1.5),
    },
  },
  icons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginRight: theme.spacing(0.5),
  },
  rewardIcon: {
    border: '1px solid black',
    minWidth: '32px',
    height: '20px',
    lineHeight: '18px',
    textAlign: 'center',
    margin: theme.spacing(0.5),
    background: '#C9EAB1',
  },
  typeIcon: {
    margin: theme.spacing(0.5),
  },
  alignRight: {
    marginLeft: 'auto',
  },
  targetDate: {
    marginLeft: 'auto',
    color: theme.palette.text.secondary,
    fontSize: '1rem',
  },
}))

export default function Timepoint({ data, isAlternate, isLast, isClient, onOpenModal, userType, onToggleIsComplete }) {
  const classes = useStyles()

  function getDateTime() {
    if (!data.date) {
      return <Typography className={classes.onlyDate}>TBA</Typography>
    }

    if (data.timepointType === TimepointType.Appointment || data.timepointType === TimepointType.PhoneCall) {
      return (
        <>
          <Typography>{getShortFormattedDate(data.date)}</Typography>
          <Typography>{getFormattedTime(data.date)}</Typography>
        </>
      )
    }

    return <Typography className={classes.onlyDate}>{getShortFormattedDate(data.date)}</Typography>
  }

  const mainInfo = (
    <Paper className={classes.mainInfo} elevation={2} variant="outlined">
      <div className={classes.icons}>
        {data.reward > 0 && (
          <Typography variant="overline" className={classes.rewardIcon}>
            {'$' + data.reward}
          </Typography>
        )}
        {data.timepointType === TimepointType.Diy && <DiyIcon className={classes.typeIcon} data-test="diy-icon" />}
        {data.timepointType === TimepointType.PhoneCall && (
          <PhoneIcon className={classes.typeIcon} data-test="phone-icon" />
        )}
        {data.timepointType === TimepointType.Appointment && (
          <InPersonIcon className={classes.typeIcon} data-test="appointment-icon" />
        )}
      </div>
      <Typography className={classes.name}>{data.name}</Typography>
      {!isClient && data.targetDate && <Typography className={classes.targetDate}>{data.targetDate}</Typography>}
      <div className={data.targetDate ? null : classes.alignRight}>
        {isClient && data.timepointType === TimepointType.Appointment && (
          <IconButton
            edge="end"
            size="small"
            color="secondary"
            onClick={() => onOpenModal(data.timepointId, ModalType.EventDetails)}
            data-test="event-details-button"
          >
            <InfoIcon />
          </IconButton>
        )}
        {!isClient && (
          <TimepointMenu
            timepointId={data.timepointId}
            timepointType={data.timepointType}
            isComplete={data.isComplete}
            userType={userType}
            hasSurvey={data.hasSurvey}
            onOpenModal={onOpenModal}
            onToggleIsComplete={onToggleIsComplete}
          />
        )}
      </div>
    </Paper>
  )

  return (
    <TimelineItem className={classes.root} data-test="timepoint">
      {isAlternate ? (
        <TimelineOppositeContent className={classes.dateTime}>{getDateTime()}</TimelineOppositeContent>
      ) : (
        <TimelineOppositeContent className={classes.mainInfoWrapper}>{mainInfo}</TimelineOppositeContent>
      )}

      <TimelineSeparator>
        <TimelineDot variant="outlined" className={classes.dot}>
          {data.isComplete && <CheckCircleIcon className={classes.check} data-test="timepoint-complete-icon" />}
        </TimelineDot>
        {!isLast && <TimelineConnector className={classes.connector} />}
      </TimelineSeparator>
      {isAlternate ? (
        <TimelineContent className={classes.mainInfoWrapper}>{mainInfo}</TimelineContent>
      ) : (
        <TimelineContent className={classes.dateTime}>{getDateTime()}</TimelineContent>
      )}
    </TimelineItem>
  )
}
