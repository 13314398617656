import { BrowserRouter as Router } from 'react-router-dom'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DayJsUtils from '@date-io/dayjs'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import '@fontsource/lato'
import '@fontsource/lato/700.css'
import { ApiProvider } from './ApiContext'
import { AuthProvider } from './AuthContext'
import { UserProvider } from './UserContext'
import { ClientProvider } from './ClientContext'
import { NonClientProvider } from './NonClientContext'
import { SnackbarProvider } from 'notistack'

const breakpoints = createBreakpoints({})

const theme = createTheme({
  palette: {
    primary: {
      main: '#002F71',
    },
    secondary: {
      main: '#0E47E5',
      light: '#ebf0ff',
    },
    accent: {
      main: '#CC3C79',
      light: '#ffe8f2',
      hover: '#b32561',
      border: 'rgba(204, 60, 121, 0.5)',
    },
    red: {
      main: '#e53935',
      light: '#ffe9e9',
      border: 'rgba(229, 57, 53, 0.5)',
    },
    background: {
      default: '#FFFFFF',
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: ['Lato', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    h1: {
      fontSize: '3.5rem',
      fontFamily: 'Lato',
      [breakpoints.down('sm')]: { fontSize: '3rem' },
    },
    h2: { fontSize: '1.75rem', fontFamily: 'Lato' },
    h3: { fontSize: '1.5rem', fontFamily: 'Lato', [breakpoints.down('xs')]: { fontSize: '1.3rem' } },
    // h4: { fontSize: '2rem' },
    // h5: { fontSize: '1.5rem' },
    // h6: { fontSize: '1.25rem' },
  },
})

export default function AppProviders({ children }) {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DayJsUtils}>
          <AuthProvider>
            <SnackbarProvider
              maxSnack={8}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <ApiProvider>
                <UserProvider>
                  <NonClientProvider>
                    <ClientProvider>{children}</ClientProvider>
                  </NonClientProvider>
                </UserProvider>
              </ApiProvider>
            </SnackbarProvider>
          </AuthProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Router>
  )
}
