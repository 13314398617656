import { makeStyles } from '@material-ui/core/styles'
import RefreshButton from '../RefreshButton'

const useStyles = ({ maxWidth }) =>
  makeStyles((theme) => ({
    root: {
      maxWidth,
      margin: 'auto',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(3),
      },
    },
  }))

export default function MainContent({ children, currentIndex, index, maxWidth = '1280px' }) {
  const classes = useStyles({ maxWidth })()

  return (
    <main
      className={classes.root}
      role="main tabpanel"
      hidden={currentIndex !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {children}
      <RefreshButton />
    </main>
  )
}
