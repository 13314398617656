import { makeStyles } from '@material-ui/core/styles'
import { IconButton, Avatar } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(1),
    left: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      bottom: '56px',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  avatar: {
    width: '50px',
    height: '50px',
    backgroundColor: theme.palette.accent.main,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.4)',
  },
}))

export default function RefreshButton() {
  const classes = useStyles()

  return (
    <IconButton className={classes.root} onClick={() => window.location.reload()} aria-label="refresh">
      <Avatar className={classes.avatar}>
        <RefreshIcon />
      </Avatar>
    </IconButton>
  )
}
