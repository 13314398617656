import { createContext, useContext, useState, useEffect, useCallback } from 'react'
import UserType from '../enums/UserType'
import { useApi } from './ApiContext'
import { useUser } from './UserContext'

const ClientContext = createContext()

function ClientProvider({ children }) {
  const api = useApi()
  const user = useUser()
  const [clientInfo, setClientInfo] = useState(null)

  const fetchClientInfo = useCallback(async () => {
    const res = await api.fetchClientInfo(user.data.id, UserType.Client)
    if (res) {
      setClientInfo(res)
    } else {
      console.error('client info not found')
    }
  }, [api, user])

  useEffect(() => {
    if (user.data && user.data.type === UserType.Client && !clientInfo) {
      fetchClientInfo()
    }
  }, [user, clientInfo, fetchClientInfo])

  return (
    <ClientContext.Provider value={{ info: clientInfo, refresh: fetchClientInfo, setInfo: setClientInfo }}>
      {children}
    </ClientContext.Provider>
  )
}

function useClient() {
  const context = useContext(ClientContext)
  if (context === undefined) {
    throw new Error('useClient must be used within a ClientProvider')
  }
  return context
}

export { ClientProvider, useClient }
