import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    textAlign: 'center',
    top: '50%',
    transform: 'translateY(-50%)',
    position: 'absolute',
  },
  text: {
    color: theme.palette.text.secondary,
    fontSize: '2rem',
  },
}))

export default function NoClientSelected() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography className={classes.text}>Select a client in the dropdown above</Typography>
    </div>
  )
}
