import { useState, useEffect, useCallback, useRef } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useUser } from '../context/UserContext'
import UserType from '../enums/UserType'

// totals 30 min
const timeBeforeLogoutWarning = 29.5 * 60 * 1000
const timeBeforeLogout = 0.5 * 60 * 1000

export default function useLogoutTimer() {
  const user = useUser()
  const [showWarning, setShowWarning] = useState(false)
  const [warningSecondsLeft, setWarningSecondsLeft] = useState(timeBeforeLogout / 1000)

  const sessionTimeout = useRef(null)
  const warningCountdownInterval = useRef(null)

  const restartTimeout = useCallback(async () => {
    if (user.data && user.data.type !== UserType.Client) {
      clearTimeout(sessionTimeout.current)
      sessionTimeout.current = setTimeout(() => setShowWarning(true), timeBeforeLogoutWarning)
    }
  }, [setShowWarning, user])

  const restartInterval = useCallback(async () => {
    clearInterval(warningCountdownInterval.current)
    warningCountdownInterval.current = setInterval(() => setWarningSecondsLeft((prevState) => prevState - 1), 1000)
  }, [setWarningSecondsLeft])

  // start session timeout when non-client user logs in
  useEffect(() => {
    restartTimeout()
    return () => {
      clearTimeout(sessionTimeout.current)
      sessionTimeout.current = null
    }
  }, [user, restartTimeout])

  // start countdown when lagout warning dialog opens
  useEffect(() => {
    if (showWarning) {
      setWarningSecondsLeft(timeBeforeLogout / 1000)
      restartInterval()
    }
    return () => {
      clearInterval(warningCountdownInterval.current)
      warningCountdownInterval.current = null
    }
  }, [showWarning, restartInterval, setWarningSecondsLeft])

  // log out if logout warning countdown goes to 0
  useEffect(() => {
    if (warningSecondsLeft === 0) {
      logout()
    }
  }, [warningSecondsLeft])

  function stayLoggedIn() {
    restartTimeout()
    restartInterval()
    setShowWarning(false)
  }

  function logout() {
    window.location.href = '/auth/logout'
  }

  useIdleTimer({
    onAction: restartTimeout,
    eventsThrottle: 5000,
  })

  return { showWarning, handleLogout: logout, handleStayLoggedIn: stayLoggedIn, warningSecondsLeft }
}
