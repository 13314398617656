import { makeStyles } from '@material-ui/core/styles'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useNonClient } from '../../context/NonClientContext'

const useStyles = makeStyles((theme) => ({
  clientSelectWrapper: {
    padding: theme.spacing(2),
    background: theme.palette.secondary.light,
  },
  autocomplete: {
    minWidth: '180px',
    marginLeft: theme.spacing(3),
  },
  textField: {
    background: 'white',
    borderRadius: '4px',
  },
}))

export default function ClientSelect() {
  const classes = useStyles()
  const nonClient = useNonClient()
  const history = useHistory()

  function selectClient(client) {
    nonClient.setCurrentClient(client)
    if (client) {
      history.push('/client')
    }
  }

  if (!nonClient.clients) {
    return null
  }
  return (
    <Autocomplete
      className={classes.autocomplete}
      id="clients"
      value={nonClient.currentClient}
      size="small"
      options={nonClient.clients}
      getOptionLabel={(option) => option.participantId}
      onChange={(e, newValue) => selectClient(newValue)}
      defaultValue={nonClient.currentClient}
      getOptionSelected={(option, value) => option.participantId === value.participantId}
      renderInput={(params) => (
        <TextField
          {...params}
          className={classes.textField}
          placeholder="Select client"
          color="secondary"
          variant="outlined"
          data-test="client-select"
        />
      )}
    />
  )
}
