import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Select, FormControl, MenuItem, TextField } from '@material-ui/core'
import ClientInfoFieldWrapper from './ClientInfoFieldWrapper'

const useStyles = makeStyles((theme) => ({
  selectFormControl: {
    flexShrink: 0,
  },
  select: {
    marginTop: theme.spacing(0.5),
    fontSize: '1.3rem',
  },
  selectWrapper: {
    display: 'flex',
    alignItems: 'baseline',
    width: '100%',
  },
  otherWrapper: {
    display: 'flex',
  },
  otherHint: {
    marginLeft: theme.spacing(2),
    flexShrink: 0,
  },
  otherTextField: {
    marginLeft: theme.spacing(1),
    width: '100%',
  },
}))

const other = 'Other'

export default function ClientInfoSelect({ label, initialValue, options, onUpdateField }) {
  const classes = useStyles()
  const [value, setValue] = useState(initialValue)
  const [isOtherSelected, setIsOtherSelected] = useState(options.includes(initialValue) ? false : initialValue)
  const [otherValue, setOtherValue] = useState(options.includes(initialValue) ? null : initialValue)
  const [showEmptyOtherValueError, setShowEmptyOtherValueError] = useState(false)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  function handleSelectChange(val) {
    if (val === other) {
      setValue(val)
      setIsOtherSelected(true)
    } else {
      setValue(val)
      setOtherValue(null)
      setIsOtherSelected(false)
      onUpdateField(val)
    }
  }

  function handleTextFieldKeyDown(e) {
    if (e.key === 'Enter') {
      handleUpdateFieldWithOtherValue()
    }
  }

  function handleUpdateFieldWithOtherValue() {
    if (!otherValue) {
      setShowEmptyOtherValueError(true)
    } else {
      onUpdateField(otherValue)
    }
  }

  function handleTextFieldChange(val) {
    setOtherValue(val)
    if (val) {
      setShowEmptyOtherValueError(false)
    } else {
      setShowEmptyOtherValueError(true)
    }
  }

  return (
    <ClientInfoFieldWrapper label={label}>
      <div className={classes.selectWrapper}>
        <FormControl variant="outlined" size="small" className={classes.selectFormControl} color="secondary">
          <Select
            value={isOtherSelected ? other : value}
            onChange={(e) => handleSelectChange(e.target.value)}
            size="small"
            className={classes.select}
            data-test="client-info-select"
          >
            {options.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
            <MenuItem key={other} value={other}>
              {other}
            </MenuItem>
          </Select>
        </FormControl>
        {isOtherSelected && (
          <>
            <Typography className={classes.otherHint}>Please specify: </Typography>
            <TextField
              className={classes.otherTextField}
              size="small"
              value={otherValue}
              onChange={(e) => handleTextFieldChange(e.target.value)}
              onBlur={handleUpdateFieldWithOtherValue}
              onKeyDown={(e) => handleTextFieldKeyDown(e)}
              color="secondary"
              error={showEmptyOtherValueError}
              helperText={showEmptyOtherValueError ? 'This field cannot be empty' : null}
              inputProps={{ 'data-test': 'client-info-other-input' }}
            />
          </>
        )}
      </div>
    </ClientInfoFieldWrapper>
  )
}
