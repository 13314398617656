import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Paper, Button } from '@material-ui/core'
import { getLongFormattedDate, addDaysToDate, isDateToday, getDaysBetweenTodayAndDate } from '../../utils/helpers'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    textAlign: 'center',
    padding: theme.spacing(2),
    background: '#ebf0ff',
  },
  dueToday: {
    border: '4px solid #cc3c79',
  },
  header: {
    fontSize: '1rem',
    textTransform: 'uppercase',
    marginBottom: '8px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
    },
  },
  completeBy: {
    fontSize: '1.2rem',
    lineHeight: 1.4,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1rem',
    },
  },
  date: {
    fontSize: '1.2rem',
    lineHeight: 1.4,
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1rem',
    },
  },
  surveyButton: {
    display: 'block',
    marginBottom: theme.spacing(1),
    marginLeft: 'auto',
    marginRight: 'auto',
    minWidth: '460px',
    fontSize: '1.4rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      minWidth: 0,
    },
  },
}))

export default function SurveyReminder({ name, startDate, onOpenSurvey }) {
  const classes = useStyles()
  // add only 13 days instead of 14 because the survey is released at midnight on day 1
  // and ends at mignight on day 14 (which is the end of day 13)
  const endDate = addDaysToDate(13, startDate)
  const daysUntilEndDate = getDaysBetweenTodayAndDate(endDate)
  const isDueToday = daysUntilEndDate < 1

  const rootClassName = clsx(classes.root, isDueToday && classes.dueToday)

  const header = isDueToday ? 'Survey due today!' : 'Survey available'

  return (
    <Paper className={rootClassName} variant="outlined" square data-test="survey-reminder">
      <Typography className={classes.header}>{header}</Typography>
      <Button
        onClick={onOpenSurvey}
        variant="contained"
        size="large"
        color="secondary"
        className={classes.surveyButton}
      >
        {name}
      </Button>
      <Typography className={classes.completeBy}>Complete by end of:</Typography>
      <Typography className={classes.date}>{isDateToday(endDate) ? 'TODAY' : getLongFormattedDate(endDate)}</Typography>
    </Paper>
  )
}
