import { Avatar, Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.accent.main,
  },
}))

export default function FormContainer({ children, maxWidth = 'xs' }) {
  const classes = useStyles()

  return (
    <Container component="main" maxWidth={maxWidth}>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        {children}
      </div>
    </Container>
  )
}
