import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import AppProviders from './context/AppProviders'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'

ReactDOM.render(
  <React.StrictMode>
    <AppProviders>
      <App />
    </AppProviders>
  </React.StrictMode>,
  document.getElementById('root')
)

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    alert(`A new version of this app is now available. Close this alert to update and refresh.`)
    registration.waiting?.postMessage({ type: 'SKIP_WAITING' })
    registration.waiting?.addEventListener('statechange', (e) => {
      if (e.target.state === 'activated') {
        window.location.reload()
      }
    })
  },
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
