import { useState } from 'react'
import TimelineIcon from '@material-ui/icons/CheckCircle'
import ContactsIcon from '@material-ui/icons/Phone'
import ResourcesIcon from '@material-ui/icons/Public'
import JournalIcon from '@material-ui/icons/LibraryBooks'
import { ImageFilterHdr } from 'mdi-material-ui'
import Tabs from '../components/Tabs/Tabs'
import TabPanel from '../components/Tabs/TabPanel'
import Timeline from '../components/Timeline/Timeline'
import Journal from '../components/client/Journal/Journal'
import Contacts from '../components/client/Contacts/Contacts'
import GoalSetting from '../components/GoalSetting/GoalSetting'
import ClientResources from '../components/client/ClientResources/ClientResources'
import { writeStorage, useLocalStorage } from '@rehooks/local-storage'
import { useClient } from '../context/ClientContext'
import Spinner from '../components/common/Spinner'
import { updateServiceWorkerRegistrations } from '../utils/helpers'

function ClientHome() {
  const [initialTabIndex] = useLocalStorage('tabIndex')
  const [tabIndex, setTabIndex] = useState(initialTabIndex || 0)
  const client = useClient()

  function handleTabChange(i) {
    setTabIndex(i)
    writeStorage('tabIndex', i)
    updateServiceWorkerRegistrations()
  }

  const tabData = [
    { name: 'Timeline', icon: TimelineIcon },
    { name: 'Journal', icon: JournalIcon },
    { name: 'Contacts', icon: ContactsIcon },
    { name: 'Goal setting', icon: ImageFilterHdr },
    { name: 'Resources', icon: ResourcesIcon },
  ]

  return (
    <>
      <Tabs tabIndex={tabIndex} onTabChange={(e, i) => handleTabChange(i)} tabData={tabData} />
      <TabPanel currentIndex={tabIndex} index={0}>
        {client.info ? <Timeline isClient clientInfoId={client.info.id} onTabChange={handleTabChange} /> : <Spinner />}
      </TabPanel>
      <TabPanel currentIndex={tabIndex} index={1}>
        {client.info ? <Journal /> : <Spinner />}
      </TabPanel>
      <TabPanel currentIndex={tabIndex} index={2}>
        {client.info ? <Contacts /> : <Spinner />}
      </TabPanel>
      <TabPanel currentIndex={tabIndex} index={3}>
        {client.info ? <GoalSetting clientInfoId={client.info.id} onTabChange={handleTabChange} /> : <Spinner />}
      </TabPanel>
      <TabPanel currentIndex={tabIndex} index={4}>
        <ClientResources onTabChange={handleTabChange} />
      </TabPanel>
    </>
  )
}

export default ClientHome
