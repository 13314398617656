import { TextField, Typography, Button, Grid, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import FormContainer from '../common/FormContainer'
import useAuthSnackbars from './useAuthSnackbars'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.accent.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(2, 0),
  },
  errorList: {
    margin: 0,
    paddingLeft: theme.spacing(2),
  },
}))

export default function LogIn({ formAction, csrfToken, errorMsgs, infoMsgs }) {
  const classes = useStyles()
  useAuthSnackbars({ errorMsgs, infoMsgs })

  return (
    <FormContainer>
      <Typography component="h1" variant="h4">
        Log in
      </Typography>
      <form className={classes.form} noValidate method="POST" action={formAction}>
        <TextField
          color="secondary"
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="identifier"
          label="Email address"
          name="identifier"
          autoComplete="email"
          autoFocus
          inputProps={{ autoCapitalize: 'none' }}
        />
        <TextField
          color="secondary"
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          inputProps={{ autoCapitalize: 'none' }}
        />
        <input type="hidden" value={csrfToken} readOnly name="csrf_token" required />
        <Button type="submit" fullWidth variant="contained" size="large" color="secondary" className={classes.submit}>
          Log in
        </Button>
        <Grid container>
          <Grid item xs>
            <Link href="/auth/recovery" variant="body2" color="secondary">
              Forgot password?
            </Link>
          </Grid>
          <Grid item>
            <Link href="/auth/registration" variant="body2" color="secondary">
              {"Don't have an account? Sign Up"}
            </Link>
          </Grid>
        </Grid>
      </form>
    </FormContainer>
  )
}
