import { useState } from 'react'
import { TextField, Typography, Button, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import FormContainer from '../common/FormContainer'
import useAuthSnackbars from './useAuthSnackbars'

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(2, 0),
  },
  errorList: {
    margin: 0,
    paddingLeft: theme.spacing(2),
  },
}))

export default function PasswordReset({ formAction, csrfToken, errorMsgs, infoMsgs }) {
  const classes = useStyles()
  useAuthSnackbars({ errorMsgs, infoMsgs })

  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')

  return (
    <FormContainer>
      <form className={classes.form} noValidate method="POST" action={formAction}>
        <TextField
          color="secondary"
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="New password"
          type="password"
          id="password"
          autoComplete="current-password"
          onChange={(e) => setPassword(e.target.value)}
          inputProps={{ autoCapitalize: 'none' }}
        />
        <TextField
          color="secondary"
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="confirm-password"
          label="Confirm new password"
          type="password"
          id="confirm-password"
          autoComplete="current-password"
          onChange={(e) => setPasswordConfirm(e.target.value)}
          error={password !== passwordConfirm}
          helperText={password !== passwordConfirm ? 'Passwords must match' : null}
          inputProps={{ autoCapitalize: 'none' }}
        />
        <input type="hidden" value={csrfToken} readOnly name="csrf_token" required />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          size="large"
          color="secondary"
          className={classes.submit}
          disabled={!password || password !== passwordConfirm}
        >
          Submit
        </Button>
      </form>
    </FormContainer>
  )
}
