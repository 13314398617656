import { Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(1)}px 0`,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}))

export default function DeleteResourceDialog({ isOpen, onClose, onConfirm, resourceName }) {
  const classes = useStyles()

  return (
    <Dialog
      classes={{ paper: classes.root }}
      onClose={onClose}
      aria-labelledby={resourceName}
      open={isOpen}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle disableTypography id={resourceName}>
        <Typography variant="h2">Delete resource</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography>{`Are you sure you want to delete ${resourceName}?`}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="secondary" variant="contained">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}
