import { useEffect, useState, useCallback } from 'react'
import { useApi } from '../context/ApiContext'
import ResourceCategory from '../enums/ResourceCategory'

const categories = Object.values(ResourceCategory)

export default function useResources(clientInfoId) {
  const api = useApi()

  const [resourcesByCategory, setResourcesByCategory] = useState(
    // eslint-disable-next-line
    categories.reduce((acc, curr) => ((acc[curr] = null), acc), {})
  )

  const [clientResources, setClientResources] = useState([])

  const fetchClientResources = useCallback(
    async (id) => {
      const resources = await api.fetchClientResources(id)
      if (resources) {
        setClientResources(resources)
      }
    },
    [api]
  )

  useEffect(() => {
    if (clientInfoId) {
      fetchClientResources(clientInfoId)
    }
  }, [clientInfoId, fetchClientResources])

  async function fetchResourcesForCategory(category) {
    const resources = await api.fetchResources(category)
    setResourcesByCategory({
      ...resourcesByCategory,
      [category]: resources,
    })
  }

  async function deleteResource(resource) {
    const updatedResources = await api.deleteResource(resource.id)
    if (updatedResources) {
      setResourcesByCategory({
        ...resourcesByCategory,
        [updatedResources[0].category]: updatedResources,
      })
    }
  }

  async function createResource(params) {
    const updatedResources = await api.createResource(params)
    if (updatedResources) {
      setResourcesByCategory({
        ...resourcesByCategory,
        [updatedResources[0].category]: updatedResources,
      })
    }
  }

  async function updateResource(resourceId, params) {
    const updatedResources = await api.updateResource(resourceId, params)
    if (updatedResources) {
      setResourcesByCategory({
        ...resourcesByCategory,
        [updatedResources[0].category]: updatedResources,
      })
    }
    if (clientInfoId && clientResources.find((cr) => cr.id === resourceId)) {
      fetchClientResources(clientInfoId)
    }
  }

  async function moveResourceUp(resourceId, category) {
    moveResource(resourceId, category, -1)
  }

  async function moveResourceDown(resourceId, category) {
    moveResource(resourceId, category, 1)
  }

  async function moveResource(resourceId, category, direction) {
    const resource1 = resourcesByCategory[category].find((r) => r.id === resourceId)
    const resource2 = resourcesByCategory[category].find((r) => r.sequenceIndex === resource1.sequenceIndex + direction)

    const updatedResources = await api.switchResourceSequenceIndices(resource1.id, resource2.id)
    if (updatedResources) {
      setResourcesByCategory({
        ...resourcesByCategory,
        [updatedResources[0].category]: updatedResources,
      })
    }
  }

  async function addClientResource(clientInfoId, resourceId) {
    const updatedClientResources = await api.addClientResource(clientInfoId, resourceId)
    if (updatedClientResources) {
      setClientResources(updatedClientResources)
    }
  }

  async function removeClientResource(clientInfoId, resourceId) {
    const updatedClientResources = await api.removeClientResource(clientInfoId, resourceId)
    if (updatedClientResources) {
      setClientResources(updatedClientResources)
    }
  }

  async function moveClientResourceUp(clientInfoId, resourceId) {
    moveClientResource(clientInfoId, resourceId, -1)
  }

  async function moveClientResourceDown(clientInfoId, resourceId) {
    moveClientResource(clientInfoId, resourceId, 1)
  }

  async function moveClientResource(clientInfoId, resourceId, direction) {
    const resource1 = clientResources.find((r) => r.id === resourceId)
    const resource2 = clientResources.find((r) => r.sequenceIndex === resource1.sequenceIndex + direction)

    const updatedResources = await api.switchClientResourceSequenceIndices(clientInfoId, resource1.id, resource2.id)
    if (updatedResources) {
      setClientResources(updatedResources)
    }
  }

  return {
    clientResources,
    resourcesByCategory,
    fetchResourcesForCategory,
    createResource,
    deleteResource,
    moveResourceUp,
    moveResourceDown,
    updateResource,
    addClientResource,
    removeClientResource,
    moveClientResourceUp,
    moveClientResourceDown,
    moveClientResource,
  }
}
