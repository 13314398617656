import { useState, useEffect } from 'react'
import { Typography, List, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import MainContent from '../common/MainContent'
import MainContentHeading from '../common/MainContentHeading'
import AddGoalDialog from './AddGoalDialog'
import EditGoalDialog from './EditGoalDialog'
import Goal from './Goal'
import goalIcon from './goal-icon.png'
import DeleteGoalDialog from './DeleteGoalDialog'
import { GOAL_SETTING_AFFIRMATIONS } from '../../constants'

const useStyles = makeStyles((theme) => ({
  subtitle: {
    marginBottom: theme.spacing(2),
  },
  headingIcon: {
    width: '80px',
    marginBottom: theme.spacing(-2),
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      width: '64px',
      marginBottom: theme.spacing(-1.5),
      marginRight: theme.spacing(1),
    },
  },
  addButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

export default function Goals({ items, onAddGoal, onUpdateGoal, onDeleteGoal }) {
  const classes = useStyles()
  const [isAddingNewGoal, setIsAddingNewGoal] = useState(false)
  const [editingGoalId, setEditingGoalId] = useState(null)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [randomAffirmation, setRandomAffirmation] = useState(null)

  useEffect(() => {
    const affirmation = GOAL_SETTING_AFFIRMATIONS[Math.floor(Math.random() * GOAL_SETTING_AFFIRMATIONS.length)]
    setRandomAffirmation(affirmation)
  }, [])

  function handleAddGoal(params) {
    setIsAddingNewGoal(false)
    onAddGoal(params)
  }

  function handleUpdateGoal(params, successMsg) {
    onUpdateGoal(editingGoalId, params, successMsg)
    setEditingGoalId(null)
  }

  function handleDeleteGoal() {
    onDeleteGoal(editingGoalId, false)
    setEditingGoalId(null)
    setShowDeleteConfirmation(null)
  }

  return (
    <MainContent>
      <MainContentHeading
        text="My goals & plans"
        icon={<img src={goalIcon} alt="Goals" className={classes.headingIcon} />}
      />
      <Typography className={classes.subtitle} align="center">
        {randomAffirmation}
      </Typography>
      <List>
        {items.map((i) => (
          <Goal key={i.id} name={i.name} dateDue={i.dateDue} type={i.type} onClick={() => setEditingGoalId(i.id)} />
        ))}
      </List>
      <div style={!items.length ? { textAlign: 'center' } : null}>
        <Button
          color="secondary"
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setIsAddingNewGoal(true)}
          className={classes.addButton}
        >
          Add goal
        </Button>
      </div>
      {isAddingNewGoal && <AddGoalDialog isOpen onClose={() => setIsAddingNewGoal(false)} onSubmit={handleAddGoal} />}
      {!!editingGoalId && (
        <EditGoalDialog
          params={items.find((i) => i.id === editingGoalId)}
          isOpen={!!editingGoalId}
          onClose={() => setEditingGoalId(null)}
          onSubmit={handleUpdateGoal}
          onTryDelete={() => setShowDeleteConfirmation(true)}
        />
      )}
      {showDeleteConfirmation && (
        <DeleteGoalDialog
          isOpen
          onClose={() => setShowDeleteConfirmation(false)}
          onConfirm={handleDeleteGoal}
          isCelebration={false}
        />
      )}
    </MainContent>
  )
}
