import { useEffect, useRef, useState } from 'react'
import { DatePicker, TimePicker } from '@material-ui/pickers'
import { makeStyles } from '@material-ui/core/styles'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Button,
  Popover,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import AddressIcon from '@material-ui/icons/RoomOutlined'
import EditIcon from '@material-ui/icons/Edit'
import DateIcon from '@material-ui/icons/Event'
import HoursIcon from '@material-ui/icons/Schedule'
import { getLongFormattedDate, getFormattedTime, getStartOfDay } from '../../utils/helpers'
import EditableText from '../common/EditableText'
import TimepointType from '../../enums/TimepointType'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(1)}px 0`,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  infoRow: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
  },
  infoText: {
    fontWeight: 700,
  },
  infoInput: {
    width: '300px',
  },
  contactText: {
    marginTop: theme.spacing(1),
  },
  contactLink: {
    verticalAlign: 'baseline',
  },
  contactButtonWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
  editButton: {
    marginLeft: 'auto',
    color: theme.palette.secondary.main,
  },
}))

export default function EventDetails({
  isOpen,
  isClient,
  name,
  initialDate,
  initialAddress,
  timepointType,
  timepointId,
  onClose,
  onSave,
  onGoToContacts,
}) {
  const classes = useStyles()

  const [address, setAddress] = useState(null)
  const [date, setDate] = useState(null)
  const [isEditingAddress, setIsEditingAddress] = useState(false)
  const [isEditingDate, setIsEditingDate] = useState(false)
  const [isEditingTime, setIsEditingTime] = useState(false)

  const dateRef = useRef()
  const timeRef = useRef()

  useEffect(() => {
    if (isOpen && timepointId) {
      setAddress(initialAddress)
      setDate(initialDate)
    }
  }, [isOpen, timepointId, initialAddress, initialDate])

  function handleChangeDate(newDate) {
    if (timepointType === TimepointType.Diy) {
      // set time to midnight so that survey reminders appear anytime on the day the survey is released
      setDate(getStartOfDay(newDate))
    } else {
      setDate(newDate)
    }
  }

  const editAddress = (
    <>
      <EditableText
        typographyProps={{ className: classes.infoText }}
        textFieldProps={{ className: classes.infoInput, inputProps: { 'data-test': 'event-address-input' } }}
        value={address}
        onChangeValue={(value) => setAddress(value)}
        isEditing={isEditingAddress}
        onFinishEditing={() => setIsEditingAddress(false)}
        autofocus
      />
      <IconButton
        size="small"
        className={classes.editButton}
        onClick={() => setIsEditingAddress(true)}
        aria-label="edit address"
      >
        <EditIcon />
      </IconButton>
    </>
  )

  const editDate = (
    <>
      <IconButton
        size="small"
        className={classes.editButton}
        aria-controls="date-selection"
        aria-haspopup="true"
        onClick={() => setIsEditingDate(true)}
        aria-label="edit date"
      >
        <EditIcon />
      </IconButton>
      <Popover
        id="date-selection"
        open={isEditingDate}
        anchorEl={dateRef.current}
        onClose={() => setIsEditingDate(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <DatePicker autoOk variant="static" value={date} onChange={handleChangeDate} />
      </Popover>
    </>
    //   <KeyboardDatePicker
    //   clearable
    //   value={date}
    //   onChange={handleChangeDate}
    //   format="MM/dd/yyyy"
    //   keyboardIcon={<EditIcon color="secondary" />}
    // />
  )

  const editTime = (
    <>
      <IconButton
        size="small"
        className={classes.editButton}
        aria-controls="time-selection"
        aria-haspopup="true"
        onClick={() => setIsEditingTime(true)}
        aria-label="edit time"
      >
        <EditIcon />
      </IconButton>
      <Popover
        id="time-selection"
        open={isEditingTime}
        anchorEl={timeRef.current}
        onClose={() => setIsEditingTime(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <TimePicker autoOk variant="static" value={date} onChange={handleChangeDate} />
      </Popover>
    </>
  )

  return (
    <Dialog
      classes={{ paper: classes.root }}
      onClose={onClose}
      aria-labelledby="appointment-info"
      open={isOpen}
      maxWidth="xs"
      fullWidth
      data-test="event-details-dialog"
    >
      {timepointId && (
        <>
          <DialogTitle disableTypography id="appointment-info">
            <Typography variant="h2">{name}</Typography>
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {timepointType === TimepointType.Appointment && (
              <div className={classes.infoRow} data-test="event-address">
                <AddressIcon className={classes.icon} />
                {isClient ? <Typography className={classes.infoText}>{address}</Typography> : editAddress}
              </div>
            )}
            <div className={classes.infoRow} ref={dateRef} data-test="event-date">
              <DateIcon className={classes.icon} />
              <Typography className={classes.infoText}>{date ? getLongFormattedDate(date) : '-'}</Typography>
              {!isClient && editDate}
            </div>
            {(timepointType === TimepointType.Appointment || timepointType === TimepointType.PhoneCall) && (
              <div className={classes.infoRow} ref={timeRef} data-test="event-time">
                <HoursIcon className={classes.icon} />
                <Typography className={classes.infoText}>{date ? getFormattedTime(date) : '-'}</Typography>
                {!isClient && editTime}
              </div>
            )}
            {isClient && (
              <>
                <Typography className={classes.contactText}>
                  If you need to change your appointment or have any questions:
                </Typography>
                <div className={classes.contactButtonWrapper}>
                  <Button
                    fullWidth
                    className={classes.contactButton}
                    variant="contained"
                    color="secondary"
                    onClick={() => onGoToContacts()}
                  >
                    Contact your connector
                  </Button>
                </div>
              </>
            )}
          </DialogContent>
          {!isClient && (
            <DialogActions>
              <Button onClick={onClose} color="secondary">
                Cancel
              </Button>
              <Button onClick={() => onSave({ address, date })} color="secondary">
                Save
              </Button>
            </DialogActions>
          )}
        </>
      )}
    </Dialog>
  )
}
