const GroupType = {
  NonCash: 'non_cash',
  Cash: 'cash',
}

export function getGroupTypeString(groupType) {
  if (groupType === GroupType.NonCash) {
    return 'Non Cash'
  }
  return 'Cash'
}

export default GroupType
