import { useEffect } from 'react'
import { useSnackbar } from 'notistack'
import SnackbarCloseButton from '../common/SnackbarCloseButton'

export default function useAuthSnackbars({ errorMsgs, infoMsgs }) {
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    errorMsgs.forEach((msg) =>
      enqueueSnackbar(msg, {
        variant: 'error',
        preventDuplicate: true,
        persist: true,
        action: (key) => <SnackbarCloseButton id={key} />,
      })
    )
    infoMsgs.forEach((msg) =>
      enqueueSnackbar(msg, {
        variant: 'info',
        preventDuplicate: true,
        persist: true,
        action: (key) => <SnackbarCloseButton id={key} />,
      })
    )
  }, [errorMsgs, infoMsgs, enqueueSnackbar])
}
