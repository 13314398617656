import { Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(1)}px 0`,
  },
}))

export default function LogoutWarningDialog({ isOpen, onLogout, onStayLoggedIn, secondsLeft }) {
  const classes = useStyles()

  return (
    <Dialog classes={{ paper: classes.root }} aria-labelledby="session-timeout" open={isOpen} maxWidth="sm" fullWidth>
      <DialogTitle disableTypography id="session-timeout">
        <Typography variant="h2">{`You will be logged out in ${secondsLeft} seconds.`}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>
          For your clients' security, you will be automatically logged out after 30 minutes of inactivity unless you
          choose to stay logged in.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onLogout} color="secondary">
          Log out
        </Button>
        <Button onClick={onStayLoggedIn} color="secondary" variant="contained">
          Stay logged in
        </Button>
      </DialogActions>
    </Dialog>
  )
}
