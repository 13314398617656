import { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Contact from './Contact'
import MainContentHeading from '../../common/MainContentHeading'
import { useApi } from '../../../context/ApiContext'
import { useClient } from '../../../context/ClientContext'
import MainContent from '../../common/MainContent'
import Spinner from '../../common/Spinner'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  heading: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  grid: {
    //marginTop: theme.spacing(3),
  },
}))

export default function Contacts() {
  const classes = useStyles()
  const api = useApi()
  const client = useClient()

  const [contacts, setContacts] = useState(null)

  useEffect(() => {
    async function fetchContacts() {
      const c = await api.fetchContacts(client.info.id)
      setContacts(c)
    }
    fetchContacts()
  }, [api, client])

  if (!contacts) {
    return <Spinner />
  }

  const lastContact = contacts[contacts.length - 1]

  return (
    <MainContent>
      <div className={classes.root}>
        <MainContentHeading text="Get in touch" />
        <Grid container justifyContent="center" spacing={3} className={classes.grid}>
          {contacts.map((contact, i) =>
            i < contacts.length - 1 ? (
              <Grid key={contact.phone} item xs={12} sm={12} md={4}>
                <Contact {...contact} />
              </Grid>
            ) : null
          )}
          <Grid key={lastContact.phone} item xs={12}>
            <Contact {...lastContact} />
          </Grid>
        </Grid>
      </div>
    </MainContent>
  )
}
