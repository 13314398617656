import { useEffect, useState } from 'react'
import { Dialog, AppBar, Toolbar, Typography, IconButton, TextField, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useGoalDialogStyles } from './goalDialogStyles'
import { PartyPopper } from 'mdi-material-ui'

export default function EditCelebrationDialog({ params, onClose, isOpen, onSubmit, onTryDelete }) {
  const classes = useGoalDialogStyles()
  const [goalParams, setGoalParams] = useState(params)

  useEffect(() => {
    if (isOpen) {
      setGoalParams(params)
    }
  }, [isOpen, params])

  return (
    <Dialog className={classes.root} fullScreen onClose={onClose} open={isOpen}>
      <AppBar className={classes.appBarCelebration}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            <PartyPopper className={classes.titleIcon} />
            {goalParams.name}
          </Typography>
          <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className={classes.form}>
        <TextField
          id="name"
          label="What did I do?"
          variant="outlined"
          fullWidth
          value={goalParams.name}
          onChange={(e) => setGoalParams({ ...goalParams, name: e.target.value })}
          className={classes.field}
          required
        />
        <Button
          fullWidth
          variant="contained"
          size="large"
          color="secondary"
          className={classes.submitButtonCelebration}
          onClick={() => onSubmit(goalParams, 'Your celebration was updated.')}
          disabled={!goalParams.name || goalParams.name === params.name}
        >
          Save changes
        </Button>
        <Button fullWidth variant="outlined" size="large" className={classes.deleteButton} onClick={onTryDelete}>
          Delete celebration
        </Button>
      </div>
    </Dialog>
  )
}
