import { useSnackbar } from 'notistack'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

export default function SnackbarCloseButton({ id }) {
  const { closeSnackbar } = useSnackbar()
  return (
    <IconButton size="small" onClick={() => closeSnackbar(id)} aria-label="close">
      <CloseIcon fontSize="small" />
    </IconButton>
  )
}
