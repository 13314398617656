import { useState } from 'react'
import { List, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import MainContent from '../common/MainContent'
import MainContentHeading from '../common/MainContentHeading'
import AddCelebrationDialog from './AddCelebrationDialog'
import EditGoalDialog from './EditGoalDialog'
import EditCelebrationDialog from './EditCelebrationDialog'
import Goal from './Goal'
import celebrationIcon from './celebration-icon.png'
import DeleteGoalDialog from './DeleteGoalDialog'

const useStyles = makeStyles((theme) => ({
  subtitle: {
    marginBottom: theme.spacing(2),
  },
  headingIcon: {
    width: '80px',
    marginBottom: theme.spacing(-2),
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      width: '64px',
      marginBottom: theme.spacing(-1.5),
      marginRight: theme.spacing(1),
    },
  },
  addButton: {
    backgroundColor: theme.palette.accent.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.accent.hover,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

export default function Celebrations({ items, onAddGoal, onUpdateGoal, onDeleteGoal }) {
  const classes = useStyles()
  const [isAddingNewCelebration, setIsAddingNewCelebration] = useState(false)
  const [editingGoalId, setEditingGoalId] = useState(null)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

  function handleAddCelebration(params) {
    setIsAddingNewCelebration(false)
    onAddGoal(params)
  }

  function handleUpdateGoal(params, successMsg) {
    onUpdateGoal(editingGoalId, params, successMsg)
    setEditingGoalId(null)
  }

  function handleDeleteCelebration() {
    onDeleteGoal(editingGoalId, true)
    setEditingGoalId(null)
    setShowDeleteConfirmation(null)
  }

  function isEditingGoalACelebration() {
    return items.find((i) => i.id === editingGoalId).type === null
  }

  return (
    <MainContent>
      <MainContentHeading
        text="My celebrations & self-care"
        icon={<img src={celebrationIcon} alt="Goals" className={classes.headingIcon} />}
      />
      <List>
        {items.map((i) => (
          <Goal
            key={i.id}
            name={i.name}
            dateDue={i.dateDue}
            type={i.type}
            onClick={() => setEditingGoalId(i.id)}
            isCelebration
          />
        ))}
      </List>
      <div style={!items.length ? { textAlign: 'center' } : null}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setIsAddingNewCelebration(true)}
          className={classes.addButton}
        >
          Add celebration
        </Button>
      </div>
      {isAddingNewCelebration && (
        <AddCelebrationDialog isOpen onClose={() => setIsAddingNewCelebration(false)} onSubmit={handleAddCelebration} />
      )}
      {!!editingGoalId &&
        (isEditingGoalACelebration() ? (
          <EditCelebrationDialog
            params={items.find((i) => i.id === editingGoalId)}
            isOpen
            onClose={() => setEditingGoalId(null)}
            onSubmit={handleUpdateGoal}
            onTryDelete={() => setShowDeleteConfirmation(true)}
          />
        ) : (
          <EditGoalDialog
            params={items.find((i) => i.id === editingGoalId)}
            isOpen
            onClose={() => setEditingGoalId(null)}
            onSubmit={handleUpdateGoal}
            onTryDelete={() => setShowDeleteConfirmation(true)}
          />
        ))}
      {showDeleteConfirmation && (
        <DeleteGoalDialog
          isOpen
          onClose={() => setShowDeleteConfirmation(false)}
          onConfirm={handleDeleteCelebration}
          isCelebration
        />
      )}
    </MainContent>
  )
}
