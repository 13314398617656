import useMediaQuery from '@material-ui/core/useMediaQuery'
import NavDesktop from './NavDesktop'
import NavMobile from './NavMobile'
import { useUser } from '../../context/UserContext'
import UserType from '../../enums/UserType'

export default function Nav() {
  const user = useUser()

  // researcher's metrics tab takes up too much room for sm breakpoint
  const isSmallScreen = useMediaQuery((theme) =>
    user.data.type === UserType.Researcher ? '(max-width:1086px)' : theme.breakpoints.down('sm')
  )

  if (isSmallScreen) {
    return <NavMobile />
  }

  return <NavDesktop />
}
