import { useState } from 'react'
import { TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import GoalType, { GoalTypeIcon } from '../../enums/GoalType'
import { useGoalDialogStyles } from './goalDialogStyles'
import { BullseyeArrow } from 'mdi-material-ui'

const other = 'Other'

export default function GoalTypeSelect({ setGoalType, goalType }) {
  const classes = useGoalDialogStyles()
  const [isOtherTypeSelected, setIsOtherTypeSelected] = useState(false)

  function getGoalTypeIcon(type) {
    const Icon = GoalTypeIcon[type]
    return <Icon className={classes.typeSelectIcon} />
  }

  function handleTypeSelectChange(val) {
    if (val === other) {
      setIsOtherTypeSelected(true)
      setGoalType(null)
    } else {
      setIsOtherTypeSelected(false)
      setGoalType(val)
    }
  }

  return (
    <>
      <FormControl variant="outlined" className={classes.field} fullWidth required color="secondary">
        <InputLabel id="goal-type-select-label">What type of goal is it?</InputLabel>
        <Select
          labelId="goal-type-select-label"
          id="goalType"
          value={goalType in GoalType ? goalType : other}
          onChange={(e) => handleTypeSelectChange(e.target.value)}
          label="What type of goal is it?"
          classes={{ root: classes.typeSelect }}
        >
          {Object.entries(GoalType).map((pair) => (
            <MenuItem key={pair[0]} value={pair[0]}>
              {getGoalTypeIcon(pair[0])}
              {pair[1]}
            </MenuItem>
          ))}
          <MenuItem value={other}>
            <BullseyeArrow className={classes.typeSelectIcon} />
            {other}
          </MenuItem>
        </Select>
      </FormControl>

      {isOtherTypeSelected && (
        <>
          <TextField
            id="otherType"
            label="Other goal type"
            variant="outlined"
            required
            fullWidth
            className={classes.field}
            value={goalType}
            onChange={(e) => setGoalType(e.target.value)}
            color="secondary"
          />
        </>
      )}
    </>
  )
}
