import { useEffect, useState } from 'react'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import TimepointType from '../../enums/TimepointType'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(1)}px 0`,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}))

export default function TimepointTypeDialog({ onClose, onSave, isOpen, timepointName, initialValue }) {
  const classes = useStyles()
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return (
    <Dialog
      classes={{ paper: classes.root }}
      onClose={onClose}
      aria-labelledby={timepointName}
      open={isOpen}
      maxWidth="xs"
      fullWidth
      data-test="timepoint-type-dialog"
    >
      <DialogTitle disableTypography id={timepointName}>
        <Typography variant="h2">{timepointName}</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormControl component="fieldset">
          <FormLabel component="legend">Appointment Type</FormLabel>
          <RadioGroup
            aria-label="timepoint-type"
            name="Timepoint Type"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          >
            <FormControlLabel value={TimepointType.Appointment} control={<Radio />} label="In-person appointment" />
            <FormControlLabel value={TimepointType.PhoneCall} control={<Radio />} label="Phone call" />
            <FormControlLabel value={TimepointType.Diy} control={<Radio />} label="DIY (do-it-yourself)" />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={() => onSave(value)} color="secondary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
