import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Avatar, AppBar, Toolbar, Button, Menu, MenuItem, Divider } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import FavoriteIcon from '@material-ui/icons/Favorite'
import ProfileIcon from '@material-ui/icons/Person'
import LogOutIcon from '@material-ui/icons/ExitToApp'
import { useUser } from '../../context/UserContext'
import ListItemLink from './ListItemLink'
import NavLink from './NavLink'
import UserType from '../../enums/UserType'
import ClientSelect from './ClientSelect'
import logo from './logo.svg'
import ClientStatusSelect from './ClientStatusSelect'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  shareButton: {
    backgroundColor: theme.palette.accent.main,
    color: 'white',
    '&:hover': {
      background: '#e55491',
    },
    display: 'none', // TODO: remove when we get a real link to share my story
  },
  divider: {
    height: '40px',
    backgroundColor: 'rgba(255,255,255,0.5)',
    margin: `0 ${theme.spacing(1)}px`,
  },
  accountButton: {
    paddingLeft: theme.spacing(2),
    textTransform: 'none',
  },
  menuItem: {
    padding: 0,
    '&:hover': {
      background: theme.palette.secondary.light,
    },
  },
  listItemAvatar: {
    width: '34px',
    height: '34px',
    backgroundColor: theme.palette.accent.main,
  },
}))

export default function NavDesktop() {
  const user = useUser()
  const classes = useStyles()
  const location = useLocation()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  function handleMenu(event) {
    setAnchorEl(event.currentTarget)
  }
  function handleClose() {
    setAnchorEl(null)
  }

  return (
    <div className={classes.root}>
      <AppBar position="fixed">
        <Toolbar>
          {user.data.type === UserType.Client ? (
            <Button
              className={classes.shareButton}
              variant="contained"
              startIcon={<FavoriteIcon />}
              href="https://google.com"
            >
              Share my story
            </Button>
          ) : (
            <>
              <img src={logo} alt="logo" height="42px" />
              <ClientSelect />
              <ClientStatusSelect />
            </>
          )}
          <div className={classes.grow} />
          {user.data.type !== UserType.Client && (
            <>
              {user.data.type === UserType.Researcher && (
                <NavLink to="/metrics" label="Metrics" isSelected={location.pathname === '/metrics'} />
              )}
              <NavLink to="/overview" label="Overview" isSelected={location.pathname === '/overview'} />{' '}
              <NavLink to="/client" label="Client" isSelected={location.pathname === '/client'} />
              <NavLink to="/resources" label="Resources" isSelected={location.pathname === '/resources'} />
              <Divider orientation="vertical" className={classes.divider} />
            </>
          )}
          <Button
            aria-label="User account"
            aria-controls="account-menu"
            aria-haspopup="true"
            onClick={handleMenu}
            className={classes.accountButton}
            color="inherit"
            endIcon={<ExpandMoreIcon />}
          >
            {user.data.firstName}
          </Button>
          <Menu
            id="account-menu"
            anchorEl={anchorEl}
            keepMounted
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={open}
            onClose={handleClose}
          >
            {user.data.type !== UserType.Client && (
              <MenuItem className={classes.menuItem} onClick={handleClose}>
                <ListItemLink
                  icon={
                    <Avatar className={classes.listItemAvatar}>
                      <ProfileIcon />
                    </Avatar>
                  }
                  primary="My profile"
                  to="/profile"
                />
              </MenuItem>
            )}
            <MenuItem className={classes.menuItem} onClick={handleClose}>
              <ListItemLink
                icon={
                  <Avatar className={classes.listItemAvatar}>
                    <LogOutIcon />
                  </Avatar>
                }
                primary="Log out"
                to="/auth/logout"
              />
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </div>
  )
}
