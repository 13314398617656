import { useHistory } from 'react-router-dom'
import MainContentHeading from '../components/common/MainContentHeading'
import MainContent from '../components/common/MainContent'
import ConnectorOverviewTable from '../components/OverviewTable/ConnectorOverviewTable'
import ResearcherOverviewTable from '../components/OverviewTable/ResearcherOverviewTable'
import { useNonClient } from '../context/NonClientContext'
import { useUser } from '../context/UserContext'
import UserType from '../enums/UserType'
import { getGroupTypeString } from '../enums/GroupType'
import { getClientStatusString } from '../enums/ClientStatus'
import Spinner from '../components/common/Spinner'

function Overview() {
  const user = useUser()
  const nonClient = useNonClient()
  const history = useHistory()

  function getConnectorRows() {
    return nonClient.clients.map((client) => ({
      id: client.id,
      name: `${client.firstName} ${client.lastName}`,
      participantId: client.participantId,
      status: getClientStatusString(client.status),
      phone: client.phone,
      email: client.email,
      groupType: getGroupTypeString(client.groupType),
      lastCompletedTimepoint: client.lastCompletedTimepointName || 'None',
    }))
  }

  function getResearcherRows() {
    return nonClient.clients.map((client) => ({
      id: client.id,
      name: `${client.firstName} ${client.lastName}`,
      participantId: client.participantId,
      status: getClientStatusString(client.status),
      phone: client.phone,
      email: client.email,
      groupType: getGroupTypeString(client.groupType),
      connector: getConnectorName(client.connectorUserId),
      lastCompletedTimepoint: client.lastCompletedTimepointName || 'None',
    }))
  }

  function getConnectorName(connectorUserId) {
    const _connector = nonClient.connectors.find((c) => c.id === connectorUserId)
    return `${_connector.firstName} ${_connector.lastName}`
  }

  function goToClientTab(clientInfoId, tabIndex) {
    const client = nonClient.clients.find((c) => c.id === clientInfoId)
    nonClient.setCurrentClient(client)
    history.push(`/client?tabIndex=${tabIndex}`)
  }

  return (
    <>
      <MainContent maxWidth={user.data.type === UserType.Researcher ? '1620px' : '1400px'}>
        <MainContentHeading text="Client overview" />
        {user.data.type === UserType.Connector && nonClient.clients && (
          <ConnectorOverviewTable
            rows={getConnectorRows()}
            goToClientTimeline={(clientInfoId) => goToClientTab(clientInfoId, 1)}
            goToClientNotes={(clientInfoId) => goToClientTab(clientInfoId, 2)}
          />
        )}
        {user.data.type === UserType.Researcher && nonClient.clients && nonClient.connectors && (
          <ResearcherOverviewTable
            rows={getResearcherRows()}
            goToClientTimeline={(clientInfoId) => goToClientTab(clientInfoId, 1)}
            goToClientNotes={(clientInfoId) => goToClientTab(clientInfoId, 2)}
          />
        )}
      </MainContent>
      {!nonClient.clients && <Spinner />}
    </>
  )
}

export default Overview
