import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  AppBar,
  Avatar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  Drawer,
  List,
  ListItem,
  Divider,
} from '@material-ui/core'
import FavoriteIcon from '@material-ui/icons/Favorite'
import MenuIcon from '@material-ui/icons/Menu'
import ProfileIcon from '@material-ui/icons/Person'
import LogOutIcon from '@material-ui/icons/ExitToApp'
import OverviewIcon from '@material-ui/icons/TableChart'
import ClientIcon from '@material-ui/icons/Face'
import ResourcesIcon from '@material-ui/icons/Public'
import MetricsIcon from '@material-ui/icons/Assessment'
import ListItemLink from './ListItemLink'
import { useUser } from '../../context/UserContext'
import UserType from '../../enums/UserType'
import ClientSelect from './ClientSelect'
import ClientStatusSelect from './ClientStatusSelect'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  toolbar: {
    minHeight: '64px',
  },
  closeButton: {
    marginLeft: 'auto',
  },
  grow: {
    flexGrow: 1,
  },
  sidebarHeader: {
    width: '310px',
    padding: '16px',
  },
  shareButton: {
    background: theme.palette.accent.main,
    color: 'white',
    '&:hover': {
      background: '#e55491',
    },
    display: 'none', // TODO: remove when we get a real link to share my story
  },
  listItem: {
    padding: 0,
    '&:hover': {
      background: theme.palette.secondary.light,
    },
  },
  listItemLink: {
    height: '80px',
  },
  listItemAvatar: {
    backgroundColor: theme.palette.accent.main,
  },
}))

export default function NavMobile() {
  const user = useUser()

  const classes = useStyles()
  const [isSidebarOpen, setIsSideBarOpen] = React.useState(false)

  return (
    <div className={classes.root}>
      <AppBar position="fixed">
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={() => setIsSideBarOpen(true)}
            data-test="nav-menu-button"
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.grow} />
          {user.data.type === UserType.Client ? (
            <Button
              className={classes.shareButton}
              variant="contained"
              color="inherit"
              startIcon={<FavoriteIcon />}
              href="https://google.com"
            >
              Share my story
            </Button>
          ) : (
            <>
              <ClientSelect />
              <ClientStatusSelect />
            </>
          )}
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={isSidebarOpen} onClose={() => setIsSideBarOpen(false)}>
        <Toolbar className={classes.sidebarHeader}>
          <Typography align="left" variant="h4">
            {`Hello, ${user.data.firstName}.`}
          </Typography>
          <IconButton
            className={classes.closeButton}
            edge="end"
            color="inherit"
            onClick={() => setIsSideBarOpen(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List>
          {user.data.type !== UserType.Client && (
            <>
              {user.data.type === UserType.Researcher && (
                <ListItem className={classes.listItem} button onClick={() => setIsSideBarOpen(false)}>
                  <ListItemLink
                    icon={
                      <Avatar className={classes.listItemAvatar}>
                        <MetricsIcon />
                      </Avatar>
                    }
                    primary="Metrics"
                    to="/metrics"
                    className={classes.listItemLink}
                  />
                </ListItem>
              )}
              <ListItem className={classes.listItem} button onClick={() => setIsSideBarOpen(false)}>
                <ListItemLink
                  icon={
                    <Avatar className={classes.listItemAvatar}>
                      <OverviewIcon />
                    </Avatar>
                  }
                  primary="Overview"
                  to="/overview"
                  className={classes.listItemLink}
                />
              </ListItem>
              <ListItem className={classes.listItem} button onClick={() => setIsSideBarOpen(false)}>
                <ListItemLink
                  icon={
                    <Avatar className={classes.listItemAvatar}>
                      <ClientIcon />
                    </Avatar>
                  }
                  primary="Client"
                  to="/client"
                  className={classes.listItemLink}
                />
              </ListItem>
              <ListItem className={classes.listItem} button onClick={() => setIsSideBarOpen(false)}>
                <ListItemLink
                  icon={
                    <Avatar className={classes.listItemAvatar}>
                      <ResourcesIcon />
                    </Avatar>
                  }
                  primary="Resources"
                  to="/resources"
                  className={classes.listItemLink}
                />
              </ListItem>
              <Divider variant="middle" />
            </>
          )}
          {user.data.type !== UserType.Client && (
            <ListItem className={classes.listItem} button onClick={() => setIsSideBarOpen(false)}>
              <ListItemLink
                icon={
                  <Avatar className={classes.listItemAvatar}>
                    <ProfileIcon />
                  </Avatar>
                }
                primary="My profile"
                to="/profile"
                className={classes.listItemLink}
              />
            </ListItem>
          )}
          <ListItem className={classes.listItem} button onClick={() => setIsSideBarOpen(false)}>
            <ListItemLink
              icon={
                <Avatar className={classes.listItemAvatar}>
                  <LogOutIcon />
                </Avatar>
              }
              primary="Log out"
              to="/auth/logout"
              className={classes.listItemLink}
            />
          </ListItem>
        </List>
      </Drawer>
    </div>
  )
}
