import { makeStyles } from '@material-ui/core/styles'
import { Typography, Paper } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    background: 'rgba(0, 0, 0, 0.12)',
    margin: '0 auto',
    textAlign: 'center',
  },
}))

export default function NoReminder() {
  const classes = useStyles()

  return (
    <Paper className={classes.root} variant="outlined" square>
      <Typography variant="overline">No upcoming events</Typography>
    </Paper>
  )
}
