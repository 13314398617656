import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { Link as RouterLink } from 'react-router-dom'

const useStyles = makeStyles({
  root: {
    //padding: 0,
  },
})

export default function ListItemLink({ icon, primary, to, onClick = () => {}, ...moreProps }) {
  const classes = useStyles()

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} onClick={onClick} {...itemProps} />),
    [to, onClick]
  )

  return (
    <ListItem className={classes.root} button component={renderLink} {...moreProps}>
      {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
      <ListItemText primary={primary} />
    </ListItem>
  )
}
