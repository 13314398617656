const ClientStatus = {
  WebScreenedOut: 'web_screened_out',
  WebScreenedIn: 'web_screened_in',
  ScreenedOut: 'screened_out',
  ScreenedIn: 'screened_in',
  Active: 'active',
  DelayedInContact: 'delayed_in_contact',
  DelayedAtRisk: 'delayed_at_risk',
  Inactive: 'inactive',
  Withdrawn: 'withdrawn',
  PassedAway: 'passed_away'
}

const clientStatusStrings = {
  [ClientStatus.WebScreenedOut]: 'Web Screened out',
  [ClientStatus.WebScreenedIn]: 'Web Screened in',
  [ClientStatus.ScreenedOut]: 'Screened out',
  [ClientStatus.ScreenedIn]: 'Screened in',
  [ClientStatus.Active]: 'Active',
  [ClientStatus.DelayedInContact]: 'Delayed - in contact',
  [ClientStatus.DelayedAtRisk]: 'Delayed - at risk',
  [ClientStatus.Inactive]: 'Inactive',
  [ClientStatus.Withdrawn]: 'Withdrawn',
  [ClientStatus.PassedAway]: 'Passed away'
}

export function getClientStatusString(status) {
  return clientStatusStrings[status]
}

export default ClientStatus
