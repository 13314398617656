//import UserType from '../../enums/UserType'
import TextFieldDialog from './TextFieldDialog'

export default function TimepointNotes({ onClose, onSave, isOpen, timepointName, notes, userType }) {
  return (
    <TextFieldDialog
      onClose={onClose}
      onSave={onSave}
      isOpen={isOpen}
      name={`Notes for ${timepointName}`}
      initialValue={notes}
      isEditable={true}
    />
  )
}
