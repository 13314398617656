import { useEffect, useState } from 'react'
import { DatePicker } from '@material-ui/pickers'
import { Dialog, AppBar, Toolbar, Typography, IconButton, TextField, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useGoalDialogStyles } from './goalDialogStyles'
import { PartyPopper, ImageFilterHdr } from 'mdi-material-ui'
import GoalTypeSelect from './GoalTypeSelect'
import GoalType, { GoalTypeIcon } from '../../enums/GoalType'
import { BullseyeArrow } from 'mdi-material-ui'

export default function EditGoalDialog({ params, onClose, isOpen, onSubmit, onTryDelete }) {
  const classes = useGoalDialogStyles()
  //const [nodateDue, setNodateDue] = useState(false)
  const [goalParams, setGoalParams] = useState(params)

  function getGoalTypeIcon() {
    const Icon = goalParams.type in GoalType ? GoalTypeIcon[goalParams.type] : BullseyeArrow
    return <Icon className={classes.titleIcon} />
  }

  useEffect(() => {
    if (isOpen) {
      setGoalParams(params)
    }
  }, [isOpen, params])

  function cannotSave() {
    return (
      !goalParams.name ||
      !goalParams.type ||
      !goalParams.dateSet ||
      JSON.stringify(goalParams) === JSON.stringify(params)
    )
  }

  return (
    <Dialog className={classes.root} fullScreen onClose={onClose} open={isOpen}>
      <AppBar className={params.isComplete ? classes.appBarCelebration : classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {getGoalTypeIcon()}
            {goalParams.name}
          </Typography>
          <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className={classes.form}>
        <TextField
          id="name"
          label={params.isComplete ? 'What did I do' : 'What do I want to do'}
          variant="outlined"
          fullWidth
          value={goalParams.name}
          onChange={(e) => setGoalParams({ ...goalParams, name: e.target.value })}
          className={classes.field}
          required
          inputProps={{ maxLength: 52 }}
          color="secondary"
        />
        <GoalTypeSelect goalType={goalParams.type} setGoalType={(val) => setGoalParams({ ...goalParams, type: val })} />
        <DatePicker
          id="dateSet"
          label="When did I set this goal?"
          inputVariant="outlined"
          value={goalParams.dateSet}
          onChange={(date) => setGoalParams({ ...goalParams, dateSet: date })}
          fullWidth
          className={classes.field}
          required
          autoOk
          color="secondary"
          format="MMM DD"
        />
        <DatePicker
          id="dateDue"
          clearable
          label={params.isComplete ? 'When did I do this?' : 'When do I want to do this?'}
          inputVariant="outlined"
          value={goalParams.dateDue}
          onChange={(date) => setGoalParams({ ...goalParams, dateDue: date })}
          className={classes.field}
          fullWidth
          autoOk
          color="secondary"
          format="MMM DD"
        />
        <TextField
          id="steps"
          label={params.isComplete ? 'What steps did I take?' : 'What steps do I need to take?'}
          fullWidth
          multiline
          variant="outlined"
          color="secondary"
          rows={4}
          maxRows={300}
          value={goalParams.steps}
          onChange={(e) => setGoalParams({ ...goalParams, steps: e.target.value })}
          className={classes.field}
        />
        <TextField
          id="support"
          label="Who supported me to take these steps?"
          fullWidth
          multiline
          variant="outlined"
          color="secondary"
          rows={4}
          maxRows={300}
          value={goalParams.support}
          onChange={(e) => setGoalParams({ ...goalParams, support: e.target.value })}
          className={classes.field}
        />
        <Button
          fullWidth
          variant="contained"
          size="large"
          color="secondary"
          className={params.isComplete ? classes.submitButtonCelebration : classes.submitButton}
          onClick={() => onSubmit(goalParams)}
          disabled={cannotSave()}
        >
          Save changes
        </Button>
        {params.isComplete ? (
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            size="large"
            className={params.isComplete ? classes.moveBackToGoalsButton : classes.completeButton}
            onClick={() => onSubmit({ ...goalParams, isComplete: false }, 'Your goal was moved back to the Goals tab.')}
            startIcon={<ImageFilterHdr />}
          >
            Move back to goals tab
          </Button>
        ) : (
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            size="large"
            className={classes.completeButton}
            onClick={() =>
              onSubmit(
                { ...goalParams, isComplete: true },
                'Congratulations! Your goal was moved to the Celebrations tab.'
              )
            }
            startIcon={<PartyPopper />}
          >
            Complete goal
          </Button>
        )}
        <Button fullWidth variant="outlined" size="large" className={classes.deleteButton} onClick={onTryDelete}>
          {params.isComplete ? 'Delete celebration' : 'Delete goal'}
        </Button>
      </div>
    </Dialog>
  )
}
